import { Example } from "../SurveyType";
import visual from "../assets/ex1_visual.png";
import git from "../assets/ex1_git.png";
import jdif from "../assets/ex1_json_diff.png";
import original from "../assets/ex1_original.png"
import patch_img from "../assets/ex1_patch.png"

const json = {
  name: "Example Node",
  children: [
    {
      name: "Child 1",
      details: {
        age: 10,
        favoriteColor: "blue",
      },
    },
    {
      name: "Child 2",
      children: [
        {
          name: "Grandchild 1",
          details: {
            age: 5,
            favoriteColor: "green",
          },
        },
        {
          name: "Grandchild 2",
          details: {
            age: 3,
            favoriteColor: "yellow",
          },
        },
      ],
    },
    {
      name: "Child 3",
      details: {
        age: 8,
        favoriteColor: "red",
      },
    },
  ],
};

const patch = [
  {
    op: "add",
    path: "/children/2/children",
    value: [
      { name: "Grandchild 2", details: { age: 3, favoriteColor: "yellow" } },
    ],
  },
  { op: "remove", path: "/children/1/children/1" },
  { op: "remove", path: "/children/0/details/age" },
];

const training: Example = {
  title: "Question 1",
  github_description: `<img src="${git}" />`,
  json_diff_com_description: `<img src="${jdif}" />`,
  text_description: `<p>
  Original Document:<br>
  <img src=${original} /><br>
  Applied Patch:<br>
  <img src=${patch_img} />
  </p>
  `,
  visualizer_description: `<img src="${visual}" />`,
  questions: [
    {
      question: "Whats the age of the inserted child?",
      type: "number",
      question_id: "2",
    },
    {
      question: "How many changing operations(deletions, insertions and updates) are performed?",
      type: "number",
      question_id: "3",
    },
  ],
  question_id: "example1",
};

export default training;
