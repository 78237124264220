import { JSONCostModel } from "../types/CostModel";
import { TreeIndex, TreeIndexJSON } from "../types/TreeIndexJSON"
import { Matrix } from "./matrix";
import * as fs from "fs";

export type Operation = {
  name: [number, number]
  possibles: string[]
  cost: number
  array_mapping?: Matrix
  mapping: Array<{node_source: number, node_target: number}>
}

export class JEDIBaselineTreeIndex {
  cm: JSONCostModel;

  constructor(costmodel: JSONCostModel){
    this.cm = costmodel
  }

  jedi(t1: TreeIndex, t2: TreeIndex) {
    let subproblem_counter = 0;

    let t1_input_size = t1.tree_size
    let t2_input_size = t2.tree_size
    let larger_tree_size = Math.max(t1_input_size, t2_input_size)
    
    const dt = new Matrix(t1_input_size + 1, t2_input_size + 1, Infinity);
    const df = new Matrix(t1_input_size + 1, t2_input_size + 1, Infinity);
    let e = new Matrix(t1_input_size + 1, t2_input_size + 1, Infinity);
    const hungarianCm: number[][] = new Array(2*larger_tree_size)

    const hungary = new Hungarian(hungarianCm)
    for(let a = 0; a < 2*larger_tree_size; a++){
      hungarianCm[a] = []
      for(let b = 0; b < 2*larger_tree_size; b++){
        hungarianCm[a].push(0)
      }
    }


    dt.set(0,0,0)
    df.set(0,0,0)


    // Deletion initialization
    for(let i = 1; i <= t1_input_size; i++){
      df.set(i,0, 0)

      for(let k = 1; k <= t1.postl_to_children[i-1].length; k++){
        df.add(i,0, dt.get(t1.postl_to_children[i-1][k-1] + 1, 0))
      }

      dt.set(i,0,df.get(i,0) + this.cm.del(t1.postl_to_label_id[i-1]))
    }
    //insertion Initializatio
    for(let j = 1; j <= t2_input_size; j++){
      df.set(0,j,0)

      for(let k = 1; k <= t2.postl_to_children[j-1].length; k++){
        df.add(0,j, dt.get(0,t2.postl_to_children[j-1][k-1] + 1))
      }

      dt.set(0,j,df.get(0, j) + this.cm.ins(t2.postl_to_label_id[j - 1]))
    }
    
    let min_for_ins = Infinity
    let min_tree_ins = Infinity
    let min_for_del = Infinity
    let min_tree_del = Infinity
    let min_for_ren = Infinity
    let min_tree_ren = Infinity

    let operations = new Array<Operation>()

    for(let i = 1; i <= t1_input_size; i++){
      for(let j = 1; j <= t2_input_size; j++){
        //Cost for deletion in forest
        min_for_del = Infinity
        min_tree_del = Infinity
        for(let t = 1; t <= t2.postl_to_children[j-1].length; t++){
          min_for_del = Math.min(min_for_del,
            (df.get(i, t2.postl_to_children[j-1][t-1] + 1) -
            df.get(0, t2.postl_to_children[j-1][t-1] + 1)))

          min_tree_del = Math.min(min_tree_del,
            dt.get(i, t2.postl_to_children[j-1][t-1] + 1) -
            dt.get(0, t2.postl_to_children[j-1][t-1] + 1) 
            )
        }
        min_for_del += df.get(0,j)
        min_tree_del+= dt.get(0,j)
        //cost for insertion in forest
        min_for_ins = Infinity
        min_tree_ins = Infinity
        for(let s = 1; s <= t1.postl_to_children[i-1].length; s++){
          min_for_ins = Math.min(min_for_ins, 
            (df.get(t1.postl_to_children[i-1][s-1] + 1, j) - 
             df.get(t1.postl_to_children[i-1][s-1] + 1, 0)));
          min_tree_ins = Math.min(min_tree_ins, 
            (dt.get(t1.postl_to_children[i-1][s-1] + 1, j) - 
             dt.get(t1.postl_to_children[i-1][s-1] + 1, 0)));
        }
        min_for_ins +=df.get(i,0)
        min_tree_ins += dt.get(i,0)

        //cost for renaming
        min_for_ren = Infinity
        min_tree_ren = Infinity
        const t1_type = t1.postl_to_type[i-1]
        const t2_type = t2.postl_to_type[j-1]

        let mapping: Array<any> = []
        let array_mapping: Matrix | undefined;
        if(t1_type == 1 && t2_type == 1){ //String edit distance
          e = new Matrix(t1.postl_to_children[i-1].length + 1, t2.postl_to_children[j-1].length + 1, 0)
          e.set(0,0,0)

          for(let s = 1; s <= t1.postl_to_children[i-1].length; s++){
            e.set(s,0, e.get(s-1, 0) + dt.get(t1.postl_to_children[i-1][s-1] + 1, 0))
          }
          for(let t = 1; t <= t2.postl_to_children[j-1].length; t++){
            e.set(0, t, e.get(0, t-1) + dt.get(0,t2.postl_to_children[j-1][t-1] + 1))
          }

          let a = -1
          let b = -1
          let c = -1
          for(let s = 1; s <= t1.postl_to_children[i-1].length; s++){
            for(let t = 1; t <= t2.postl_to_children[j-1].length; t++){
              subproblem_counter++
              a = e.get(s, t-1) + dt.get(0, t2.postl_to_children[j-1][t-1]+1)
              b = e.get(s-1, t) + dt.get(t1.postl_to_children[i-1][s-1]+1,0)
              c = e.get(s-1, t-1) + dt.get(t1.postl_to_children[i-1][s-1]+1, t2.postl_to_children[j-1][t-1]+1)
              e.set(s,t, Math.min(a,b,c))
            }
          }
          //"mappings generieren für array nodes"

          array_mapping = e
          
          
          min_for_ren = e.get(t1.postl_to_children[i-1].length, t2.postl_to_children[j-1].length)
        } else if(t1_type == 2 && t2_type == 2 && t1.postl_to_children[i-1].length > 0 && t2.postl_to_children[j-1].length > 0) {
          //two keys, cost of mapping their child
          min_for_ren = dt.get(t1.postl_to_children[i-1][0]+1, t2.postl_to_children[j-1][0]+1)
          mapping = [{r: 0,c: 0}]
        } else if (t1_type == 3 && t2_type == 3){
          min_for_ren = 0 //values are leafes
        } else {
          //hungry an
          //prepare some sort of magic matrix
          min_for_ren = Infinity
          let matrix_size = t1.postl_to_children[i-1].length + t2.postl_to_children[j-1].length

          for(let s = 1; s <= matrix_size; s++){
            for(let t = 1; t <= matrix_size; t++){
              if(s <= t1.postl_to_children[i-1].length){
                if(t <= t2.postl_to_children[j-1].length){
                  hungarianCm[s-1][t-1] = dt.get(
                    t1.postl_to_children[i-1][s-1] + 1,
                    t2.postl_to_children[j-1][t-1] + 1
                  )
                }else{
                  hungarianCm[s-1][t-1] = t1.postl_to_size[t1.postl_to_children[i-1][s-1]]
                }
              }else{
                if(t <= t2.postl_to_children[j-1].length){
                  hungarianCm[s-1][t-1] = t2.postl_to_size[t2.postl_to_children[j-1][t-1]]
                }else{
                  hungarianCm[s-1][t-1] = 0
                }
              }
            }
          }


          let {costs, mappings} = hungary.execute_hungarian(matrix_size)
          min_for_ren = costs
          mapping = mappings
        }
        //DECISIONTIME YIPPIE
        let val_forest = Math.min(min_for_del, min_for_ins, min_for_ren)

        df.set(i,j, val_forest)

        if(t1.postl_to_type[i-1] != t2.postl_to_type[j-1]){
          min_tree_ren = df.get(i,j) + this.cm.del(t1.postl_to_label_id[i-1]) + this.cm.ins(t2.postl_to_label_id[j-1])
        }else{
          min_tree_ren = df.get(i,j) + this.cm.ren(t1.postl_to_label_id[i-1],t2.postl_to_label_id[j-1])
        }


        let val_tree = Math.min(min_tree_del, min_tree_ins, min_tree_ren)
        let op: Operation = {
          name: [t1.postl_to_label_id[i-1],t2.postl_to_label_id[j-1]],
          mapping: [],
          possibles: [],
          cost: val_tree
        }
        if(val_tree == min_tree_ren){
          op.possibles.push("rename")
        }
        if(val_tree == min_tree_del){
          op.possibles.push("delete")
        }
        if(val_tree == min_tree_ins){
          op.possibles.push("insert")
        }
        if(t1_type == 1 && t2_type == 1){
          op.array_mapping = array_mapping
        }else{

          op.mapping = mapping.map(({r,c}) => {
            return { 
              node_source: t1.postl_to_label_id[t1.postl_to_children[i-1][r]],
              node_target: t2.postl_to_label_id[t2.postl_to_children[j-1][c]]
            }
          }).filter((m) => m.node_source && m.node_target)
        }
        operations.push(op)
        dt.set(i,j, val_tree)
      }
    }

    return { jedi: dt.get(t1_input_size, t2_input_size), operations, dt, df }
  }
}

class Hungarian {
  step: number;
  cost_matrix: number[][] = [];
  matrix_size: number = 0;
  mask_matrix: number[][] = [];
  row_cover: number[] = [];
  col_cover: number[] = [];
  path_col_0: number = -1;
  path_row_0: number = -1;
  
  constructor(cost_matrix: number[][]){
    this.step = 1
    this.cost_matrix = cost_matrix
  }

  execute_hungarian(matrix_size: number) {
    this.matrix_size = matrix_size

    this.mask_matrix = new Array(matrix_size)
    for(let i = 0; i < this.mask_matrix.length; i++){
      let t = new Array(matrix_size).fill(0)
      this.mask_matrix[i] = t
    }
    this.row_cover = new Array(this.matrix_size)
    this.col_cover = new Array(this.matrix_size)
    this.row_cover.fill(0)
    this.col_cover.fill(0)

    this.path_row_0 = -1
    this.path_col_0 = -1
    let costs = 0

    let orig_matrix: number[][] = []
    for(let r = 0; r < this.matrix_size; r++){
      orig_matrix[r] = []
      for(let c = 0; c < this.matrix_size; c++){
        orig_matrix[r].push(this.cost_matrix[r][c])
      }
    }

    let done = false
    let maps: Array<{r: number, c: number}> = []
    this.step = 1
    while(!done){
      if(this.step == 1){
        this.step_one()
        continue;
      }else if(this.step == 2) {
        this.step_two()
        continue;
      }else if(this.step == 3) {
        this.step_three()
        continue;
      }else if(this.step == 4) {
        this.step_four()
        continue;
      }else if(this.step == 5) {
        this.step_five()
        continue;
      }else if(this.step == 6) {
        this.step_six()
        continue;
      }else if(this.step == 7) {
        let{cost, mappings} = this.step_seven(orig_matrix)
        costs += cost
        done = true
        maps = mappings
        continue;
      }
    }

    // if(costs == 5){
    //   console.log({orig_matrix, maps})
    // } 
    return {costs, mappings: maps};    
  }

  print_matrix(){
    let rows = this.cost_matrix.length
    let cols = this.cost_matrix[0].length
    console.log("costmatrix:")
    for(let r = 0; r < rows; r++){
      let s= ""
      for(let c = 0; c < cols; c++){
        s += " " + this.cost_matrix[r][c]
      }
    }
  }

  step_one(){
    let min_in_row: number;

    for(let r = 0; r < this.matrix_size; r++){
      min_in_row = this.cost_matrix[r][0]

      for(let c = 0; c < this.matrix_size; c++){
        if(this.cost_matrix[r][c] < min_in_row) min_in_row = this.cost_matrix[r][c]
      }
      for(let b=0; b < this.matrix_size; b++){
        this.cost_matrix[r][b] = this.cost_matrix[r][b]- min_in_row 
      }
    }
    this.step=2
  }

  step_two(){
    for(let r = 0; r < this.matrix_size; r++){
      for(let c = 0; c < this.matrix_size; c++){
        if(this.cost_matrix[r][c] == 0 && this.row_cover[r] == 0 && this.col_cover[c] == 0){
          this.mask_matrix[r][c] = 1
          this.row_cover[r] = 1
          this.col_cover[c] = 1
        }
      }
    }

    for(let r = 0; r < this.matrix_size; r++){
      this.row_cover[r] = 0
    }
    for(let c = 0; c < this.matrix_size; c++){
      this.col_cover[c] = 0
    }



    this.step = 3
  }

  step_three(){
    let col_count = 0;
    for(let r = 0; r < this.matrix_size; r++){
      for(let c = 0; c < this.matrix_size; c++){
        if(this.mask_matrix[r][c] == 1){
          this.col_cover[c] = 1
        }
      }
    }


    for(let c = 0; c < this.matrix_size; c++){
      if(this.col_cover[c] == 1){
        col_count++
      }
    }

    if(col_count >= this.matrix_size){
      this.step = 7
    }else{
      this.step = 4
    }
  }

  step_four(){
    let row = -1
    let col = -1

    while(true){
      let {rowc, colc} = this.find_a_zero(row, col)
      row = rowc
      col = colc

      if(row == -1){
        this.step = 6
        return;
      }else{
        this.mask_matrix[row][col] = 2
        if(this.star_in_row(row)){
          col = this.find_star_in_row(row, col)
          this.row_cover[row] = 1
          this.col_cover[col] = 0
        }else{
          this.path_row_0 = row
          this.path_col_0 = col
          this.step = 5
          return;
        }
      }
    }
  }

  step_five() {
    let r = -1
    let c = -1

    let path: number[][] = [];
    path.push([]); // Add a new row (an empty array) to 'path'
    path[path.length - 1].push(this.path_row_0); // Assuming path_row_0 is a number
    path[path.length - 1].push(this.path_col_0); // Assuming path_col_0 is also a number

    while(1){
      r = this.find_star_in_col(r, path[path.length -1][1])
      if(r > -1){
        path.push([])
        path[path.length-1].push(r)
        path[path.length-1].push(path[path.length-2][1])
      }else{
        break;
      }

      c = this.find_prime_in_row(path[path.length - 1][0], c)
      path.push([])
      path[path.length -1].push(path[path.length - 2][0])
      path[path.length -1].push(c)
    }

    this.augment_path(path)
    this.clear_covers()
    this.erase_primes()
    this.step=3
  }

  step_six(){
    let min_val = this.find_smallest(Infinity)
   
    for(let r = 0; r < this.matrix_size; r++){
      for(let c = 0; c < this.matrix_size; c++){
        if(this.row_cover[r] == 1){
          this.cost_matrix[r][c] = (this.cost_matrix[r][c] || 0) +  min_val
        }
        if(this.col_cover[c] == 0){
          this.cost_matrix[r][c] = (this.cost_matrix[r][c] || 0) -  min_val
        }
      }
    }

    this.step = 4
  }

  step_seven(cost_matrix: number[][]): {cost:number, mappings: Array<{r: number, c: number}>} {
    let mappings = []
    let cost = 0
    for(let r = 0; r <this.matrix_size; r++){
      for(let c = 0; c < this.matrix_size; c++){
        if(this.mask_matrix[r][c] == 1){ //wird evtl aufeinander gematched
          cost += cost_matrix[r][c]
          mappings.push({r,c})
        }
      }
    }

    return {cost, mappings};
  }

  find_smallest(min_val: number, print: boolean = false): number {
    for(let r = 0; r < this.matrix_size; r++){
      for(let c = 0; c < this.matrix_size; c++){
        if(this.row_cover[r] == 0 && this.col_cover[c] == 0){
          if(print){
          }
          if(min_val > this.cost_matrix[r][c]) {
            min_val = this.cost_matrix[r][c]
          }
        }
      }
    }

    return min_val
  }

  find_prime_in_row(row: number, col: number) {
    col = -1
    for(let c = 0; c < this.matrix_size; c++){
      if(this.mask_matrix[row][c] == 2){
        col = c
      }
    }

    return col
  }
  find_star_in_col(row: number, col: number) {
    row = -1
    for(let r = 0; r < this.matrix_size; r++){
      if(this.mask_matrix[r][col] == 1){
        row = r
      }
    }
    return row
  }
  augment_path(path: number[][]) {
    for(let p = 0; p < path.length; p++){
      let p1 = path[p][0]
      let p2 = path[p][1]
      if(this.mask_matrix[p1][p2] == 1){
        this.mask_matrix[p1][p2] = 0
      }else{
        this.mask_matrix[p1][p2] = 1
      }
    }
  }
  clear_covers() {
    for(let r = 0; r < this.matrix_size; r++){
      this.row_cover[r] = 0
    }
    for(let c = 0; c < this.matrix_size; c++){
      this.col_cover[c] = 0
    }
  }
  erase_primes() {
    for(let r = 0; r < this.matrix_size; r++){
      for(let c = 0; c < this.matrix_size; c++){
        if(this.mask_matrix[r][c] == 2){
          this.mask_matrix[r][c] = 0
        }
      }
    }
  }

  find_star_in_row(row: number, col: number): number {
    col = -1

    for(let c = 0; c < this.matrix_size; c++){
      if(this.mask_matrix[row][c] == 1){
        col = c
      }
    }

    return col
  }
  star_in_row(row: number) {
    let found_star = false

    for(let c = 0; c < this.matrix_size; c++){
      if(this.mask_matrix[row][c]== 1){
        found_star = true
      }
    }

    return found_star
  }



  find_a_zero(row: number, col: number): {rowc: number, colc: number} {
    row = -1
    col = -1

    for(let r = 0; r < this.matrix_size; r++){
      for(let c = 0; c < this.matrix_size; c++){
        if(this.cost_matrix[r][c] == 0 && this.row_cover[r] == 0 && this.col_cover[c] == 0){
          return { rowc: r, colc: c}
        }
      }
    }
    return {rowc: row, colc: col}
  }

}

