import { JSONLabel } from "./label";

interface ILabelDictionary {
  insert: (label: JSONLabel) => number;
  get: (id: number) => JSONLabel;
  getNumberOfLabels: () => number;
  clear: () => void;
  size: () => number;
}

export class LabelDictionary implements ILabelDictionary{
  dict: JSONLabel[];
  constructor() {
    this.dict = [];
  }
  insert(label: JSONLabel){
    const index = this.dict.findIndex(el => el.deepEquals(label))
    
    if(index >= 0) return index

    this.dict.push(label)
    label.setNum(this.size()-1)
    return this.size()-1
  }
  print(){
    let s = ""
    this.dict.forEach((a, i) => {
      s += i +  ";" + a.getName() + "-" + a.getType() +"\n"
    })
    return s;
  }

  get(id: number) {
    return this.dict[id]
  }
  getNumberOfLabels() {
    return this.size()
  }
  size() {
    return this.dict.length;
  }

  clear() {
    this.dict = []
  }
}

export class JSONCostModel{
  label_dic: LabelDictionary;

  constructor(label_dic: LabelDictionary) {
    this.label_dic = label_dic
  }
  ren(node1_id: number, node2_id: number) {
    const n1 = this.label_dic.get(node1_id)
    const n2 = this.label_dic.get(node2_id)

    if((n1.getType() == 1 && n2.getType() == 0)|| (n1.getType() == 0 && n2.getType() == 1)) return 2;

    if(n1.getType() != n2.getType()) return Infinity;
  
    if(n1.getLabel() === n2.getLabel()) return 0;

    return 1
  }
  del(node_id: number) {
    return 1;
  }
  ins(node_id: number) {
    return 1;
  };

}