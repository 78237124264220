import { JSONCostModel, LabelDictionary } from "../types/CostModel";
import { TreeIndexJSON } from "../types/TreeIndexJSON";
import { JSONLabel } from "../types/label";


export class TreeIndexer {
  start_preorder: number;
  start_postorder: number;
  subtree_max_depth: number;
  height: number;

  constructor(){
    this.start_preorder = 0
    this.start_postorder = 0;
    this.subtree_max_depth = 0;
    this.height = 0
  }

  index_tree(t1: TreeIndexJSON, n: JSONLabel, ld: LabelDictionary, cm: JSONCostModel) {
    let tree_size = n.getTreeSize()
    t1.tree_size = tree_size

    t1.postl_to_size = new Array(tree_size)
    t1.postl_to_parent = new Array(tree_size).fill(-1)
    t1.postl_to_children = new Array(tree_size)
    t1.postl_to_label_id = new Array(tree_size)
    t1.postl_to_type = new Array(tree_size)
    t1.postl_to_depth = new Array(tree_size)
    t1.postl_to_lch = new Array(tree_size)
    t1.postl_to_subtree_max_depth = new Array(tree_size)
    t1.postl_to_kr_ancestor = new Array(tree_size)
    t1.list_kr = []
    t1.inverted_list_label_id_to_postl = new Map<number, number[]>()
    t1.postl_to_fav_child = new Array(tree_size)
    t1.postl_to_left_fav_child = new Array(tree_size)
    t1.postl_to_height = new Array(tree_size)
    t1.postl_to_ordered_child_size = new Array(tree_size)
    t1.postl_to_favorder = new Array(tree_size)
    t1.postl_to_left_sibling = new Array(tree_size)

    this.start_preorder = 0
    this.start_postorder = 0
    let start_depth = 0
    let start_height = 0
    this.subtree_max_depth = 0
    this.height = 0

    this.index_tree_recursion(t1, n, ld, cm, start_depth, start_height, -1, false)

    let ts = tree_size-1
    let fid = 0
    this.fav_child_processing_order(t1, ts, fid)

    t1.list_kr.push(this.start_postorder-1)
    this.fill_kr_ancestors(t1)
  }

  fill_kr_ancestors(t1: TreeIndexJSON) {
    for (const i of t1.list_kr) {
      let l = i;
      while (l >= 0) {
        t1.postl_to_kr_ancestor[l] = i;
        l = t1.postl_to_lch[l];
      }
    }
    
  }

  fav_child_processing_order(t1: TreeIndexJSON, postorder: number, favorder: number) {
    if(t1.postl_to_children[postorder].length != 0){
      this.fav_child_processing_order(t1, t1.postl_to_fav_child[postorder], favorder)

      for(let t = 0; t < t1.postl_to_children[postorder].length ;t++){
        if(t1.postl_to_children[postorder][t] == t1.postl_to_fav_child[postorder]){
          continue;
        }

        this.fav_child_processing_order(t1, t1.postl_to_children[postorder][t], favorder)
      }
    }

    t1.postl_to_favorder[favorder++] = postorder
  }

  index_tree_recursion(t1: TreeIndexJSON, n: JSONLabel, ld: LabelDictionary, cm: JSONCostModel, start_depth: number, start_height: number, arg10: number, arg11: boolean) {
    let desc_sum = 0
    let label_id = ld.insert(n)
    let type = n.getType()
    let preorder_r = 0
    let postorder_r = 0
    let this_nodes_preorder = this.start_preorder
    this.start_preorder++
  
    let children_postorders: number[] = []
    let children_preorders: number[] = []
    let children_sorted_subtree_size: number[] = []


    let this_subtree_max_depth = 0
    let this_height = 0
    let is_current_child_rightmost = false
    let first_child_postorder = -1
    let subtree_size_child = -1
    let largest_subtree_size_child = -1
    let favorable_child = -1
    let left_fav_child = -1
    let previous_child_po = -1
    let num_children = 0
  
    let children = n.getChildren()
    for(let index = 0; index < children.length; index++){
      children_preorders.push(this.start_preorder)
  
      if(index == children.length-1){
        is_current_child_rightmost = true
      }
  
      subtree_size_child = this.index_tree_recursion(t1, children[index], ld, cm, start_depth+1, start_height, this_nodes_preorder, is_current_child_rightmost)
      desc_sum += subtree_size_child
  
      children_postorders.push(this.start_postorder-1)
  
      if(subtree_size_child > largest_subtree_size_child){
        largest_subtree_size_child = subtree_size_child
        left_fav_child = previous_child_po
        favorable_child = this.start_postorder - 1
      }
  
      if(this.height > this_height) this_height = this.height
    
      t1.postl_to_left_sibling[this.start_postorder-1] = previous_child_po
      children_sorted_subtree_size.push(subtree_size_child)

      if(index == 0){
        first_child_postorder = this.start_postorder-1
      }else{
        t1.list_kr.push(this.start_postorder-1)
      }
      num_children++
      previous_child_po = this.start_postorder-1  
    }

    preorder_r = t1.tree_size - 1 - this.start_postorder
    postorder_r = t1.tree_size - 1 - this_nodes_preorder

    t1.postl_to_fav_child[this.start_postorder] = favorable_child
    t1.postl_to_left_fav_child[this.start_postorder] = left_fav_child

    
    children_sorted_subtree_size.sort((a,b) => a-b) //ascending

    let children_subtree_deletion_cost: number[] = []

    for(let i = 0; i < children_sorted_subtree_size.length; i++){
      if(children_subtree_deletion_cost.length == 0){
        children_subtree_deletion_cost.push(children_sorted_subtree_size[i])
      }else{
        children_subtree_deletion_cost.push(children_subtree_deletion_cost[children_subtree_deletion_cost.length-1] + children_sorted_subtree_size[i])
      }
    }

    t1.postl_to_ordered_child_size[this.start_postorder] = children_subtree_deletion_cost

    this.height = this_height + 1
    t1.postl_to_height[this.start_postorder] = this.height

    t1.postl_to_size[this.start_postorder] = desc_sum+1

    for(let child_id of children_postorders){
      t1.postl_to_parent[child_id] = this.start_postorder
    }

    t1.postl_to_children[this.start_postorder] = children_postorders
    t1.postl_to_label_id[this.start_postorder] = label_id
    t1.postl_to_type[this.start_postorder] = type

    t1.inverted_list_label_id_to_postl.set(label_id, [...(t1.inverted_list_label_id_to_postl.get(label_id)||[]), this.start_postorder])
    t1.postl_to_depth[this.start_postorder] = start_depth
    
    if(n.isLeaf()){
      this_subtree_max_depth = start_depth
    }
    t1.postl_to_subtree_max_depth[this.start_postorder] = this_subtree_max_depth
    this.subtree_max_depth = Math.max(this.subtree_max_depth, this_subtree_max_depth)

    if(n.isLeaf()){
      this_height = start_height
    }
    t1.postl_to_height[this.start_postorder] = this_height
    this.height = Math.max(this.height, this_height)

    t1.postl_to_lch[this.start_postorder] = first_child_postorder

    this.start_postorder++
    return desc_sum + 1
  }
}
