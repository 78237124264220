
export class Matrix {
  data: number[][];
  rows: number;
  cols: number;
  constructor(rows: number, cols: number, initialValue: number) {
    this.rows = rows;
    this.cols = cols;

    this.data = []
    for(let a = 0; a < rows; a++){
      this.data.push([])
      for(let b = 0; b < cols; b++){
        this.data[a].push(initialValue)
      }
    }
  }

  fillWith(value: number) {
      this.data.forEach(row => row.fill(value));
  }

  set(row: number, col: number, value: number){
    this.data[row][col] = value
  }

  add(row: number, col: number, value: number){
    this.data[row][col] += value
  }

  get(row: number, col: number){
    return this.data[row][col]
  }

  getResult() {
    const lr = this.data[this.data.length-1]
    return lr[lr.length-1]
  }

  print(){
    let s = ""
    for(let a = 0; a < this.data.length; a++){
      for(let b = 0; b < this.data[a].length; b++){
        let add = this.data[a][b] == Infinity ? "∞" : this.data[a][b].toString()

        if(add.length != 2) add = " "+add
        s+=add+"|"
      }
      s += "\n";
    }

    s+=(`${this.data.length}x${this.data[0].length}\n`)

    return s
  }

  printWithIds(rowIds: number[], colIds: number[]) {
    let lines:string[] = []
    let cids: string = "  ;"
    cids += " ϵ;"
    for(let a = 0; a < colIds.length; a++){
      let c = "" + colIds[a]
      
      if(c.length != 2) c = " " + c

      cids += c+";"
      
    }

    lines.push(cids)
    for(let a = 0; a < this.data.length; a++){

      let s = ""
      if (a == 0){
        s = " ϵ"
      }else{
        s = "" + rowIds[a-1]
        if(s.length != 2) s = " " + s
      }
      s+=";"
      
      for(let b = 0; b < this.data[a].length; b++){
        let add = this.data[a][b] == Infinity ? "∞" : this.data[a][b].toString()

        if(add.length != 2) add = " "+add
        s+=add+";"
      }
      lines.push(s)
    }

    return lines.reduce((cum, curr) => cum += curr +"\n", "")
  }
}
