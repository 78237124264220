import type { QuestionMeta } from "../SurveyType";
import jsonsample from '../assets/introduction_json_sample.png'
import patchsample from '../assets/sample_patch.png'

const introduction: QuestionMeta = {
  title: "JSON (JavaScript Object Notation)",
  description: `
  <p>
  JSON (JavaScript Object Notation) is a lightweight data interchange format.<br><br>
  JSON is built on two structures:<br>
  * A collection of name/value pairs: In various languages, this is realized as an object, record, struct, dictionary, hash table, keyed list, or associative array.<br>
  * An ordered list of values: In most languages, this is realized as an array, vector, list, or sequence.
  <br><br>
  An Example of a JSON Document can look like this:<br>
  </p>
  <img src='${jsonsample}' /><br>
  This JSON document describes the 'Human Resources'-department, consisting of 3 Employes (Alice, Bob and Charlie) stored in an array under the key
  <span class="code">employees</span>
  `,
  question_id: "introduction",
  questions: []
}

export default introduction