import { Example } from "../SurveyType";
import visual from "../assets/ex12_visual.png";
import git from "../assets/ex12_git.png";
import jdif from "../assets/ex12_json_diff.png";
import original from "../assets/ex12_original.png"
import patch_img from "../assets/ex12_patch.png"

const training: Example = {
  title: "Question 12",
  github_description: `<img src="${git}" />`,
  json_diff_com_description: `<img src="${jdif}" />`,
  text_description: `<p>
  Original Document:<br>
  <img src=${original} /><br>
  Applied Patch:<br>
  <img src=${patch_img} />
  </p>
  `,
  visualizer_description: `<img src="${visual}" />`,
  questions: [
    {
      question: "How many members are in the 'Fullstack'-team in the new version of the JSON-Document?",
      type: "number",
      question_id: "1",
    },
    {
      question: "How many teams are in the 'development'-department in the new version of the JSON-Document?",
      type: "number",
      question_id: "2",
    },
  ],
  question_id: "example12",
};

export default training;
