import { Operation, Operation as RFCOperation} from 'rfc6902'
import { JEDIBaselineTreeIndex, Operation as EditOperation } from './jedi_baseline'
import { TreeIndex } from '../types/TreeIndexJSON'
import { JSONCostModel, LabelDictionary } from '../types/CostModel'
import { parseJSON } from './bracketParser'
import { TreeIndexer } from './tree_indexer'
import { patch_v2 } from './patch_generation'
import { resourceLimits } from 'worker_threads'


const do_baseline = (doc1: object, doc2: object) => {
  const tree1 = parseJSON(doc1, "t1")
  const tree2 = parseJSON(doc2, "t2")
  
  const indexer1 = new TreeIndexer()
  const indexer2 = new TreeIndexer()
  
  let ld = new LabelDictionary()
  let cm = new JSONCostModel(ld)
  let t1 = new TreeIndex()
  let t2 = new TreeIndex()
  indexer1.index_tree(t1, tree1, ld, cm)
  indexer2.index_tree(t2, tree2, ld, cm)
  const jedi_baseline = new JEDIBaselineTreeIndex(cm)

  return {...jedi_baseline.jedi(t1,t2), ld}
}


const jedi: (doc1: object, doc2: object) => number = (doc1, doc2) => {
  
  const { jedi } = do_baseline(doc1, doc2)


  return jedi
}

const computePatch: (doc1: object, doc2: object) => RFCOperation[] = (doc1, doc2) => {
  const {operations, ld, jedi} = do_baseline(doc1, doc2)
  // console.log({jedi})
  // console.log(operations[operations.length-1], "op")
  // console.log(operations[operations.length-1].mapping.map(({node_source, node_target}) => {
  //   let [o,t] =[ld.get(node_source), ld.get(node_target)]
  //   return [o.path, o.num, t.path, t.num]
  // }))
  return patch_v2(operations, ld)
}



const jedi_and_patch: (doc1: object, doc2: object) => {jedi: number, patch: RFCOperation[]} = (doc1, doc2) => {
  const {operations, ld, jedi} = do_baseline(doc1, doc2)

  const buildEditScript: any = (operation: EditOperation) => {
    const result: Array<EditOperation> = []
    if(operation.cost == 0) return result;
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])

    if(operation.mapping.length > 0){
      const sumMap = operation.mapping.reduce((cumm, cur) => {
        const {node_source, node_target} = cur
        const op = operations.find(a => a.name[0] == node_source && a.name[1] == node_target)
        if(op) return cumm + op.cost
  
        return cumm
      }, 0)

      node_new.children.forEach(a => {
        const mapped = operation.mapping.some(m => m.node_target == a.num)
        if(!mapped) {
          result.push({
            name: [-1, a.num],
            possibles: ["delete"],
            // @ts-ignore
            mapping: {}, 
            cost: 1
          })
        }
      })
      node_old.children.forEach(a => {
        const mapped = operation.mapping.some(m => m.node_source == a.num)
        if(!mapped) {
          result.push({
            name: [a.num, -1],
            possibles: ["insert"],
            // @ts-ignore
            mapping: {}, 
            cost: 1
          })
        }
      })
  
      if(operation.cost != sumMap) result.push(operation)
  
        operation.mapping.forEach((c) => {
        const {node_source, node_target} = c
        const op = operations.find(a => a.name[0] == node_source && a.name[1] == node_target)
        if(op) result.push(...buildEditScript(op))
      })
    }
    if(operation.array_mapping){
      let c = operation.array_mapping.cols-1;
      let r = operation.array_mapping.rows-1;
      console.log({node_new, node_old})

      while(c != 0 || r != 0){
        if(c == 0){
          r--
          continue;
        }
        if(r == 0){
          c--
          continue;
        }
        
        const rename = operation.array_mapping.get(r-1, c-1)
        const insert = operation.array_mapping.get(r, c-1)
        const del = operation.array_mapping.get(r-1, c)

        const min = Math.min(rename, insert, del)
        switch(min){
          case rename:
            const oldc = node_old.children[r-1].num
            const newc = node_new.children[c-1].num
            const op = operations.find(a => a.name[0] == oldc && a.name[1] == newc)
            if(op){
              result.push(...buildEditScript(op))
            }
            r--
            c--
            break;
          case del:
            result.push({
              name: [node_old.children[r-1].num, -1],
              possibles: ["insert"],
              // @ts-ignore
              mapping: {}, 
              cost: 1
            })
            r--
            break;
          case insert:
            result.push({
              name: [-1, node_new.children[c-1].num],
              possibles: ["delete"],
              // @ts-ignore
              mapping: {}, 
              cost: 1
            })
            c--
            break;
        }
      }

    }

    if(operation.mapping.length == 0 && !operation.array_mapping){
      if(operation.possibles.includes("insert")){
        const op = operations.find(a => a.name[0] == node_old.children[0].num && a.name[1] == node_new.num)
        if(op){
          result.push(...buildEditScript(op))
          // if(op.cost != operation.cost) result.push(operation)
        }
      }
      if(operation.possibles.includes("delete")){
        const op = operations.find(a => a.name[0] == node_old.num && a.name[1] == node_new.children[0].num)
        if(op){
          result.push(...buildEditScript(op))
          // if(op.cost != operation.cost) result.push(operation)
        }
      }
      if(operation.cost != 0) result.push(operation)
    }

    return result
  } 
  const edits: Array<EditOperation> = buildEditScript(operations[operations.length-1])
  return {patch: patch_v2(operations, ld), jedi, operations, ld, edits}
}

const makeHierarchyFromEditOperations: (operations: EditOperation[], ld: LabelDictionary) => any = (operations, ld) => {
  let result: {
    name: string;
    __rd3t: any;
    attributes?: any;
    children: Array<any>;
  } = {
    name: "",
    children: [],
    __rd3t: {},
    attributes: {
      skipPaths: true,
    },
  };


  const rootOperation = operations[operations.length-1]
  const oldRoot = ld.get(rootOperation.name[0])
  const newRoot = ld.get(rootOperation.name[1])

  result.children.push(oldRoot.makeBasicHierarchy())
  result.children.push(newRoot.makeBasicHierarchy())

  return result;
}

const makeHierarchyFromEditOperationsRecursion: (operation: EditOperation, operations: EditOperation[], ld: LabelDictionary) => any = (operation, operations, ld) => {
  let result: {
    name: string;
    __rd3t: any;
    attributes?: any;
    children: Array<any>;
  } = {
    name: "",
    children: [],
    __rd3t: {},
    attributes: {},
  };

  const old_node = ld.get(operation.name[0])
  const old_new = ld.get(operation.name[1])

  result.name = old_new.label.toString()

  return result;
}



export {
  jedi,
  computePatch,
  jedi_and_patch,
  makeHierarchyFromEditOperations
}