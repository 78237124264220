import { Example } from "../SurveyType";
import visual from "../assets/ex14_visual.png";
import git from "../assets/ex14_git.png";
import jdif from "../assets/ex14_json_diff.png";
import original from "../assets/ex14_original.png"
import patch_img from "../assets/ex14_patch.png"

const training: Example = {
  title: "Question 14",
  github_description: `<img src="${git}" />`,
  json_diff_com_description: `<img src="${jdif}" />`,
  text_description: `<p>
  Original Document:<br>
  <img src=${original} /><br>
  Applied Patch:<br>
  <img src=${patch_img} />
  </p>
  `,
  visualizer_description: `<img src="${visual}" />`,
  questions: [
    {
      question: "What key has been added to the species objects?",
      type: "text",
      question_id: "1",
    },
    {
      question: "How many species are there in total in the original JSON-Document?",
      type: "number",
      question_id: "2",
    },
    {
      question: "How many species are there in total in the new version of the JSON-Document?",
      type: "number",
      question_id: "3",
    },
  ],
  question_id: "example14",
};

export default training;
