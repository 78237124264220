//@ts-ignore
import React, {useEffect, useState} from 'react';
import Tree, { CustomNodeElementProps } from 'react-d3-tree';
import {parseJSON} from './utils/bracketParser';
import { createPatch } from 'rfc6902';
import { compare } from 'fast-json-patch';
import * as jedi from '../src/implementation/jedi';
const sample1 ={
  "ecosystem": "Amazon Rainforest",
  "layers": [
    {
      "name": "Canopy",
      "species": [
        {"commonName": "Harpy Eagle", "scientificName": "Harpia harpyja"},
        "züpfi",
        {"commonName": "Howler Monkey", "scientificName": "Alouatta caraya"},
        // {"commonName": "Sloth", "scientificName": "Bradypus variegatus"},
        // {"commonName": "Toucan", "scientificName": "Ramphastos toco"},
        // {"commonName": "Red-eyed Tree Frog", "scientificName": "Agalychnis callidryas"}
      ]
    },
    // {
    //   "name": "Understory",
    //   "species": [
    //     {"commonName": "Jaguar", "scientificName": "Panthera onca"},
    //     {"commonName": "Anaconda", "scientificName": "Eunectes murinus"},
    //     {"commonName": "Poison Dart Frog", "scientificName": "Dendrobates tinctorius"},
    //     {"commonName": "Electric Eel", "scientificName": "Electrophorus electricus"},
    //     {"commonName": "Piranha", "scientificName": "Pygocentrus nattereri"}
    //   ]
    // }
  ]
}
const sample2 = {
  "ecosystem": "Amazon Rainforest",
  "layers": [
    {
      "name": "Canopy",
      "species": [
        "simulant",
        {"commonName": "Harpy Eagle", "scientificName": "Harpia harpyja", "type": "bird"},
        {"commonName": "Howler Monkey", "scientificName": "Alouatta caraya", "type": "monkey"},
        "asfd"
        // {"commonName": "Toucan", "scientificName": "Ramphastos toco", "type": "bird"},
        // {"commonName": "Red-eyed Tree Frog", "scientificName": "Agalychnis callidryas", "type": "frog"}
      ]
    },
    // {
    //   "name": "Understory",
    //   "species": [
    //     {"commonName": "Jaguar", "scientificName": "Panthera onca", "type": "cat"},
    //     {"commonName": "Anaconda", "scientificName": "Eunectes murinus", "type": "snake"},
    //     {"commonName": "Piranha", "scientificName": "Pygocentrus nattereri", "type": "fish"}
    //   ]
    // }
  ]
}




const patch = [
  { op: "add", path: "/layers/0/species/0/type", value: "bird"},
  { op: "add", path: "/layers/0/species/1/type", value: "monkey"},
  { op: "add", path: "/layers/0/species/3/type", value: "bird"},
  { op: "add", path: "/layers/0/species/4/type", value: "frog"},
  { op: "add", path: "/layers/1/species/0/type", value: "cat"},
  { op: "add", path: "/layers/1/species/1/type", value: "snake"},
  { op: "add", path: "/layers/1/species/4/type", value: "fish"},
  { op: "remove", path: "/layers/0/species/2"},
  { op: "remove", path: "/layers/1/species/2"},
  { op: "remove", path: "/layers/1/species/3"},
]





const renderRectSvgNode = ({ nodeDatum, toggleNode }: CustomNodeElementProps) =>{
  if(nodeDatum.attributes) {
    if(nodeDatum.attributes.rerender) {
      nodeDatum.attributes.rerender = false
      toggleNode()
    }
  }

  try{

    nodeDatum.name.substring(0,15)
  }catch {
    console.log("erro", nodeDatum)
  }
  return (
    <g>
      <ellipse rx="65" ry="20" fill={nodeDatum.attributes.fill || "lightgrey"} onClick={toggleNode}></ellipse>
      {/* <circle r="15" fill={nodeDatum.attributes.fill || "lightgrey"} onClick={() => toggleNode()}/> */}
      <text textAnchor='middle' dy="5" fill="black" strokeWidth="1" onClick={toggleNode}>
        {nodeDatum.name.substring(0, 15)}
      </text>
      {nodeDatum.attributes.type == "replacement" &&
        <text textAnchor="middle" dy="30" strokeWidth="1" 
        fill='black' onClick={() => {
            if(nodeDatum.attributes.show_original){
              nodeDatum.attributes.original_name = nodeDatum.name
              nodeDatum.name = nodeDatum.attributes.replaced_node.name
              nodeDatum.attributes.show_original = false
              nodeDatum.attributes.original_children = nodeDatum.children
              nodeDatum.children = nodeDatum.attributes.replaced_node.children || []
              nodeDatum.attributes.rerender = true
              nodeDatum.attributes.fill = "brown"
            }else{
              nodeDatum.name = nodeDatum.attributes.original_name
              nodeDatum.attributes.show_original = true
              nodeDatum.attributes.rerender = true
              nodeDatum.attributes.fill = "lightblue"
              nodeDatum.children = nodeDatum.attributes.original_children
            }
            toggleNode()
        }}>
          Show {nodeDatum.attributes.show_original ? 'old' : 'new' } version
        </text>
      }
    </g>
  );
}

export default function AppD3() {
  const [chart, setChart] = useState(null)
  const [s1, setS1] = useState( JSON.stringify(sample1, null, " "))
  const [s2, setS2] = useState(JSON.stringify(sample2, null, " "))

  useEffect(() => {
    const t1 = parseJSON(sample1)
    setChart(t1.makeHierarchy({operations: patch}))
  }, [])

  return <>
      <div className='input'>
        <textarea id="t1" rows={15} value={s1} onChange={(e) => setS1(e.target.value)} placeholder='Original Document'></textarea>
        <textarea id="t2" rows={15} value={s2} onChange={(e) => setS2(e.target.value)} placeholder="Target Document"></textarea>
      </div>
      <div className="controls">
        <button onClick={() => {
          const doc1 = JSON.parse(s1)
          const doc2 = JSON.parse(s2)
          const patch = compare(doc1, doc2)
          console.log(JSON.stringify(patch, null, " "))
          const tree1 = parseJSON(doc1, "input")
          const hierarchy = tree1.makeHierarchy({operations: patch})
          console.log({hierarchy})
          setChart(tree1.makeHierarchy({operations: patch}))
        }}>Show Diff</button>
        <button onClick={() => {
          const doc1 = JSON.parse(s1)
          const doc2 = JSON.parse(s2)

          const p = jedi.computePatch(doc1,doc2)
          const element = document.createElement('a');
          const text = JSON.stringify(p, null, " ")
          const filename = "patch.json"
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
          element.setAttribute('download', filename);
        
          element.style.display = 'none';
          document.body.appendChild(element);
        
          element.click();
        
          document.body.removeChild(element);
        }}>Download Diff</button>
      </div>
    <div id="treeWrapper" style={{ width: '100vw', height: '100vh' }}>
      {chart &&
        <Tree translate={{x:( window.visualViewport?.width || 0)/2,y: 20}} orientation={'vertical'} renderCustomNodeElement={renderRectSvgNode} data={chart} />
      }
    </div>
  </>
}