//@ts-ignore
import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection, getDocs } from '@firebase/firestore';
import { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts";
import { off } from "process";

const solution = {
  "example1_github[2]": "3",
  "example1_github[3]": "3",
  "example2_json_diff[1]": "4",
  "example2_json_diff[3]": "Leader",
  "example3_visualizer[2]": "2",
  "example3_visualizer[3]": "12",
  "example4_github[1]": "Astra Luxury Model",
  "example4_github[2]": "4",
  "example5_json_diff[1]": "3",
  "example5_json_diff[2]": "1",
  "example6_visualizer[1]": "Medicine",
  "example6_visualizer[2]": "5",
  "example6_visualizer[3]": "dean",
  "example7_github[1]": "primary",
  "example7_github[2]": "2",
  "example8_json_diff[1]": "3",
  "example8_json_diff[2]": "Bob",
  "example8_json_diff[3]": "Tech Innovations Inc.",
  "example9_visualizer[1]": "Extra Care",
  "example9_visualizer[2]": "daily",
  "example10_github[1]": "brand_id",
  "example10_github[2]": "3",
  "example11_json_diff[1]": "title",
  "example11_json_diff[2]": "name",
  "example11_json_diff[3]": "5",
  "example12_visualizer[1]": "8",
  "example12_visualizer[2]": "2",
  "example13_github[1]": "3",
  "example13_github[2]": "Grade School",
  "example14_json_diff[1]": "type",
  "example14_json_diff[2]": "10",
  "example14_json_diff[3]": "7",
  "example15_visualizer[1]": "6",
  "example15_visualizer[2]": "components",
  "example15_visualizer[3]": "1"
}



const firebaseConfig = {
  apiKey: "AIzaSyAwoJPEqyMev93rR_7Srh92jdwgfmi52D4",
  authDomain: "master-thesis-surveydata.firebaseapp.com",
  projectId: "master-thesis-surveydata",
  storageBucket: "master-thesis-surveydata.appspot.com",
  messagingSenderId: "774643703427",
  appId: "1:774643703427:web:ca6dd0d2e6c9d5ddaa45b6",
  measurementId: "G-QYEQ1RN80P"
};

const gradeAnswers = (answers: any) => {
  let totalDuration = 0
  let correctAnswers = 0
  let incorrectAnswers = 0
  const solutionkeys = Object.keys(solution)
  let totalanswers = 0
  answers.forEach((answer:any) => {
    totalDuration+=answer.duration

    let answerKeys = Object.keys(answer).filter(k => solutionkeys.includes(k))
    answerKeys.forEach(key => {
      totalanswers++
      //@ts-ignore
      if(solution[key] == answer[key]){
        correctAnswers++
      }else{
        incorrectAnswers++
      }
    })
  })

  return {
    avgDuration: (totalDuration/answers.length),
    correctAnswers: (correctAnswers / (totalanswers/100)).toFixed(2),
    incorrectAnswers: (incorrectAnswers / (totalanswers/100)).toFixed(2)
  }
}


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const store = getFirestore(app)


export default function SurveyEvaluation() {

  const [github_answerdata, setGithubAnswerData] = useState<any>([])
  const [diffAnswerData, setDiffAnswerData] = useState<any>([])
  const [visAnswerData, setVisAnswerData] = useState<any>([])
  const [durationData, setDurationData] = useState<any>([])
  const [final1Data, setFinal1Data ] = useState<any>([])
  const [final2Data, setFinal2Data ] = useState<any>([])
  const [final3Data, setFinal3Data ] = useState<any>([])

  useEffect(() => {
    getDocs(collection(store, "submissions")).then(documents => {
      const answers = documents.docs.map(d => d.data())
      console.log(JSON.stringify(answers, null, " "))
      return answers
    }).then(answers => {
      const withDuration = []
      const gitAnswers =[]
      const jdiffAnswers =[]
      const visAnswers = []
      const finalAnswers = []

      for(let i = 0; i < answers.length; i++){
        const answer = answers[i]
        
        for(let j = 6; j < answer.answers.length; j++){
          answer.answers[j].duration = answer.answers[j].timestamp - answer.answers[j-1].timestamp
          const answerkeys = Object.keys(answer.answers[j])
          if(answerkeys.some(key => key.includes("github"))){
            gitAnswers.push(answer.answers[j])
            answer.answers[j].category = "github"
          }
          if(answerkeys.some(key => key.includes("diff"))){
            jdiffAnswers.push(answer.answers[j])
            answer.answers[j].category = "diff"
          }
          if(answerkeys.some(key => key.includes("visualizer"))){
            visAnswers.push(answer.answers[j])
            answer.answers[j].category = "visualizer"
          }
          if(j == 21){
            finalAnswers.push(answer.answers[j])
            answer.answers[j].category = "final"
          }
        }
        withDuration.push(answer)
      }


      return {gitAnswers,jdiffAnswers,visAnswers,finalAnswers}
    }).then(({gitAnswers, jdiffAnswers, visAnswers, finalAnswers}) => {
      const evalGit = gradeAnswers(gitAnswers)
      const evalJdiff = gradeAnswers(jdiffAnswers)
      const evalVis = gradeAnswers(visAnswers)

      setGithubAnswerData([
        //@ts-ignore
        { id: 0, value: evalGit.correctAnswers, label: 'correct' },
        //@ts-ignore
        { id: 1, value: evalGit.incorrectAnswers, label: 'incorrect' }
      ])
      setDiffAnswerData([
        //@ts-ignore
        { id: 0, value: evalJdiff.correctAnswers, label: 'correct' },
        //@ts-ignore
        { id: 1, value: evalJdiff.incorrectAnswers, label: 'incorrect' }
      ])
      setVisAnswerData([
        //@ts-ignore
        { id: 0, value: evalVis.correctAnswers, label: 'correct' },
        //@ts-ignore
        { id: 1, value: evalVis.incorrectAnswers, label: 'incorrect' }
      ])
      setDurationData([
        {id: 0, value: (evalGit.avgDuration / 1000).toFixed(0), label: 'Git-Diff'},
        {id: 1, value: (evalJdiff.avgDuration / 1000).toFixed(0), label: 'JSON-Diff'},
        {id: 2, value: (evalVis.avgDuration/1000).toFixed(0), label: 'Visualization'}
      ])

      const final1 = new Map<string, number>()
      const final2 = new Map<string, number>()
      const final3 = new Map<string, number>()
      finalAnswers.forEach(answer => {
        const q1 = answer["example16[1]"].replace()
        const q2 = answer["example16[2]"]
        const q3 = answer["example16[3]"]

        final1.set(q1, (final1.get(q1) || 0)+1)
        final2.set(q2, (final2.get(q2) || 0)+1)
        final3.set(q3, (final3.get(q3) || 0)+1)      
      })



      setFinal1Data(Array.from(final1.entries()).map((val, index) => {
        const [label, value] = val
        return {id: index, value, label: `${label} (${value})`}
      }))

      setFinal2Data(Array.from(final2.entries()).map((val, index) => {
        const [label, value] = val
        return {id: index, value, label: `${label} (${value})`}
      }))
      
      setFinal3Data(Array.from(final3.entries()).map((val, index) => {
        const [label, value] = val        
        return {id: index, value, label: `${label} (${value})`}
      }))
    })
  }, [])
  return(
      <div>
      { github_answerdata.length > 0 &&
      <div className="chart_container">
        <h2>Github Answers</h2>
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value}%`,
              data: [...github_answerdata],
            },
          ]}
          width={400}
          height={200}
          />
      </div>
      }
      <br />
      { diffAnswerData.length > 0 &&
      <div className="chart_container">
        <h2>jsondiff Answers</h2>
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value}%`,
              data: [...diffAnswerData],
            },
          ]}
          width={400}
          height={200}
          />
      </div>
      }
      <br />
      { visAnswerData.length > 0 &&
      <div className="chart_container">
        <h2>Visualization Answers</h2>
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value}%`,
              data: [...visAnswerData],
            },
          ]}
          width={400}
          height={200}
          />
      </div>
      }
      <br/>
      { durationData.length > 0 &&
      <div className="chart_container">
        <h2>Average durations</h2>
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value} sec`,
              data: [...durationData],
            },
          ]}
          width={400}
          height={200}
          />
      </div>
      }
      <br />
      { final1Data.length > 0 &&
      <div className="chart_container">
        <h2>Easiest to Interpret</h2>
        <PieChart
          series={[
            {
              data: [...final1Data],
            },
          ]}
          width={400}
          height={200}
          />
      </div>
      }
      <br />
      { final2Data.length > 0 &&
      <div className="chart_container">
        <h2>Personal Liking</h2>
        <PieChart
          series={[
            {
              data: [...final2Data],
            },
          ]}
          width={400}
          height={200}
          />
      </div>
      }
      <br />
      { final3Data.length > 0 &&
      <div className="chart_container">
        <h2>Most Information</h2>
        <PieChart
          series={[
            {
              data: [...final3Data],
            },
          ]}
          width={400}
          height={200}
          />
      </div>
      }
      <br />
    </div>
  )
}