import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppD3 from './AppD3';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import SurveyStart from './SurveyStart';
import SurveyEvaluation from './SurveyEvaluation';
import AppD3JsonTree from './AppD3JsonTree';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <div className='Header'>
      <h1>JEDI-DiffVis</h1>
    </div>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/survey" element={<SurveyStart />} />
        <Route path="/playground" element={<AppD3 />}/>
        <Route path="/playground2" element={<AppD3JsonTree />}/>
        <Route path="/evaluation" element={<SurveyEvaluation />} />
      </Routes>
    </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
