import { JSONLabel } from "../types/label";

const parseJSON = (object: any, identifier: string) => {
  const root = new JSONLabel("{}", identifier)
  
  Object.getOwnPropertyNames(object).forEach(property => {
    let p = new JSONLabel(property + ":", identifier, true)
    root.addChild(p)
    parseJSONRecursive(object[property], identifier, p)
  })

  root.setPath('/')


  return root
}

const parseJSONRecursive = (object: any, identifier: string, parent: JSONLabel) => {
  if(object == null){
    const l = new JSONLabel(null, identifier)
    parent.addChild(l)
    return l

  }
  if(typeof object == "object" && Array.isArray(object)){
    let arrayparent = new JSONLabel("[]", identifier)
    parent.addChild(arrayparent)
    object.forEach((element) => {
      parseJSONRecursive(element, identifier, arrayparent)
    });
    return arrayparent;
  }else if(typeof object == "object" && !Array.isArray(object)){
    let objectparent = new JSONLabel("{}", identifier)
    parent.addChild(objectparent)
    Object.getOwnPropertyNames(object).forEach(element => {
      let subparent = new JSONLabel(element + ":", identifier, true)
      objectparent.addChild(subparent)
      parseJSONRecursive(object[element], identifier, subparent)
    })

    return objectparent
  }else{
    const temp = new JSONLabel(object, identifier)
    parent.addChild(temp)
    return temp  
  }
}



export { parseJSON, parseJSONRecursive }