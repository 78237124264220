//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react';

export default function Question(props: any) {
  const {question, onAnswer} = props
  const contentRef = useRef()

  useEffect(() => {
    if(contentRef.current){
      contentRef.current.innerHTML = question.description
    }
  }, [contentRef, question])

  return <div className='question-container'>
    <div className='left-part'>
      <h2>{question.title}</h2>
      <div ref={contentRef}>{}</div>
      <img src={question.image} />
    </div>
    <div className='right-part'>
      <form onSubmit={(event) => {
        event.preventDefault()
        const form = event.target
        const answer = {}
        const formdata = new FormData(form)
        Array.from(formdata.entries()).forEach((value) => {
          const [name, v] = value

          answer[name] = v
        })
        if(question.confirm){
          if(window.confirm(question.confirmText || "Proceed?")) {
            onAnswer(answer)
            form.reset()
          }
        }else{
          onAnswer(answer)
          form.reset()
        }
      }}>
        {question.questions.map((q: any) => {
          console.log(q)
          return <div>
            <label for={`${question.question_id}[${q.question_id}]`}>{q.question}</label><br />
            {q.type != "select" && 
            <input required type={q.type} name={`${question.question_id}[${q.question_id}]`}/>
            }
            {q.type == "select" && 
              <select name={`${question.question_id}[${q.question_id}]`}>
                <option>default</option>
                {q.options.map((v) => {
                  return <option value={v}>{v}</option>
                })}
              </select>
            }
          </div>
        })}
        <div>
        <input type="submit" value={"Continue"} />
        </div>
      </form>
    </div>
  </div>
}