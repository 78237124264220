import { Example, QuestionMeta } from "../SurveyType";
import visual from "../assets/training_visual.png"
import git from "../assets/training_git.png"
import jdif from "../assets/training_json_diff.png"
import sample_result from "../assets/sample_result.png"
import patch from "../assets/training_patch.png"
import orig from "../assets/training_original.png"

const training: QuestionMeta = {
  title: "Question 16",
  description: `
  <p>
  These are all the chosen visualizations:<br>
  <h3>Git-Diff:</h3>
  <img src="${git}" />
  <h3>JSON-Diff:</h3>
  <img src="${jdif}" />
  <h3>Vis-Tool:</h3>
  <img src="${visual}" />
  </p>
  `,
  questions: [
    {
      question: "Which visualisation do you think is the easiest to interpret?",
      type: "select",
      options: [
        "Git-Diff",
        "JSON-Diff",
        "Vis-Tool"
      ],
      question_id: "1",
    },
    {
      question: "Which visualisation do you personaly like best?",
      type: "select",
      options: [
        "Git-Diff",
        "JSON-Diff",
        "Vis-Tool"
      ],
      question_id: "2",
    },
    {
      question: "Which visualisation do you think provides the most information?",
      type: "select",
      options: [
        "Git-Diff",
        "JSON-Diff",
        "Vis-Tool"
      ],
      question_id: "3",
    }
  ],
  question_id: "example16",
};

export default training;
