//@ts-ignore
import React, {useEffect, useState} from 'react';
import Question from './Question';
import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection, getDocs } from '@firebase/firestore';
import {survey_all, survey_git, survey_json_diff, survey_text, survey_vis} from './examples/survey_all';


const firebaseConfig = {
  apiKey: "AIzaSyAwoJPEqyMev93rR_7Srh92jdwgfmi52D4",
  authDomain: "master-thesis-surveydata.firebaseapp.com",
  projectId: "master-thesis-surveydata",
  storageBucket: "master-thesis-surveydata.appspot.com",
  messagingSenderId: "774643703427",
  appId: "1:774643703427:web:ca6dd0d2e6c9d5ddaa45b6",
  measurementId: "G-QYEQ1RN80P"
};

function getKeyWithLowestValue(obj: Object) {
  let lowestKey = null;
  let minValue = Infinity; // Initialize with the largest possible number

  // Iterate over the object
  for (const [key, value] of Object.entries(obj)) {
    // Check if the current value is less than the smallest value found so far
    if (value < minValue) {
      minValue = value;
      lowestKey = key;
    }
  }

  return lowestKey;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const store = getFirestore(app)

export default function SurveyStart() {
  const [step, setStep] = useState(0)
  const [answers, setAnswers] = useState<Array<any>>([])
  const [survey, setSurvey] = useState<any>()
  const [identifier, setIdentifier] = useState<string>("")
  

  const nextQuestion = (answer: any) => {
    setStep(step + 1)
    answer.timestamp = Date.now()
    setAnswers([...answers, answer])
  }

  useEffect(() => {
    setSurvey(survey_all)
  }, [])

  useEffect(() => {
    if(!survey) return
    if(step >= survey.length){
      console.log("solution")
      console.log(answers)
      // addDoc(collection(store, "submissions"), {answers}).then(a => {
      //   console.log(a)
      // }).catch((error: any) => {
      //   console.log({error})
      //   alert("OHOH")
      // })
    }
  }, [step])
  console.log(survey)

  return <div className='survey-container'>
    {survey != undefined &&
      <>
        {survey[step] &&
          <Question question={survey[step]} onAnswer={nextQuestion}></Question>
        }
        {step >= survey.length && 
          <h3>Thank you for participating :)</h3>
        }
      </>
    }
  </div>
}