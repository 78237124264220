import type { QuestionMeta } from "../SurveyType";
import jsonsample from '../assets/introduction_json_sample.png'
import patchsample from '../assets/sample_patch.png'

const introduction_survey: QuestionMeta = {
  title: "Topic of this study",
  description: `
  <p>
    The goal of this study is to evaluate different ways to represent the differences between two JSON-Documents. <br>
    There are text-based approaches, such as the diff shown in a GitHub Pull-Request, or various online tools. As a part of this thesis a new representation is being developed, that aims to represent the differences by displaying them in a tree-like structure that combines both the old and new version of the JSON-Documents.<br><br> 
    
    The next slides will provide you with the necessary terminology to complete the survey. During the survey you will be shown a representation of the difference between two JSON-Documents and will be asked certain questions about the differences. The first question will just be an example question to better your understanding of the task. 
  </p>
  <p>
    Thank you for participating!
  </p>
  `,
  question_id: "introduction",
  questions: []
}

export default introduction_survey