import { Example } from "../SurveyType"
import visual from "../assets/training_visual.png"
import git from "../assets/training_git.png"
import jdif from "../assets/training_json_diff.png"
import sample_result from "../assets/sample_result.png"
import patch from "../assets/training_patch.png"
import sample_collapsed from "../assets/sample_vis_collapsed.png"
import orig from "../assets/training_original.png"

const training: Example = {
  title: "Training question",
  github_description: `
  <p>
  This image represents a github diff between the starting JSON-Document and the new version of the JSON-Document.<br>
  <img src="${git}" /><br>
  To better your understanding here is the original and the updated JSON-Document:<br>
  <img src="${orig}" />
  <img src="${sample_result}" /><br>
  For this question the answers are provided for you so that you can understand how you are expected to answer.<br>
  The expected answers to the questions are in order:<br>
  * How many keys are deleted at the root level of the document?: <span class="code">2</span> (job and location)<br>
  * How many keys pairs are new at the root level of the document <span class="code">1</span> (address)<br>
  * Elements in the hobbies array after the patch: <span class="code">4</span> (Soccer, Tennis, Climbing, Cooking)<br>
  </p>
  `,
  json_diff_com_description: `<p>
  This is a visualization with the online tool <a href="https://jsondiff.com/">jsondiff.com</a>.<br>
  You will always be provided with an images representing the differences between the original JSON-Document and the new JSON-Document.<br>
  To help you understand you will also see the original JSON-Document as well as the new one in this question:<br>
  This is the old version: <br>
  <img src="${orig}" /><br>
  This is the new JSON-Document:<br>
  <img src="${sample_result}" /><br>
  <br>
  This is what the representation you will see looks like in that case:<br>
  <img src="${jdif}" />

  <br>
  For this question the answers are provided for you so that you can understand how you are expected to answer.<br>
  The expected answers to the questions are in order:<br>
  * How many keys are deleted at the root level of the document?: <span class="code">2</span> (job and location)<br>
  * How many keys pairs are new at the root level of the document <span class="code">1</span> (address)<br>
  * Elements in the hobbies array after the patch: <span class="code">4</span> (Soccer, Tennis, Climbing, Cooking)<br>
  </p>`,
  text_description: `
  <p>
  This is the original document:<br>
  <img src="${orig}" /><br>
  This is the JSON-Patch:<br>
  <img src="${patch}" /><br>
  <br>
  For this question the answers are provided for you so that you can understand how you are expected to answer.<br>
  The expected answers to the questions are in order:<br>
  * How many keys are deleted at the root level of the document?: <span class="code">2</span> (job and location)<br>
  * How many keys pairs are new at the root level of the document <span class="code">1</span> (address)<br>
  * Elements in the hobbies array after the patch: <span class="code">4</span> (Soccer, Tennis, Climbing, Cooking)<br>
  </p>`,
  visualizer_description: `
  <p>
  By coloring nodes the operation that is done is represented.
  <div style="display: flex;">
  <div class="survey-node-legend" style="background-color: red">Deletion</div>
  <div class="survey-node-legend" style="background-color: lightblue">Updating</div>
  <div class="survey-node-legend" style="background-color: yellowgreen">Insertion</div>
  </div>
  <img src="${visual}" /><br>

  The original JSON-Document and new updated JSON-Document for this example are:<br>
  <img src="${orig}" />
  <img src="${sample_result}"/><br>
  For this question the answers are provided for you so that you can understand how you are expected to answer.<br>
  The expected answers to the questions are in order:<br>
  * How many keys are deleted at the root level of the document?: <span class="code">2</span> (job and location)<br>
  * How many keys pairs are new at the root level of the document <span class="code">1</span> (address)<br>
  * Elements in the hobbies array after the patch: <span class="code">4</span> (Soccer, Tennis, Climbing, Cooking)<br>
  </p>
  <p>
    In some images certain nodes are collapsed in order to save space. These values are not empty object but have more values in the corresponding subtree.
    An example of this are the <span class="code">{}</span> nodes in the species array. They have more data underlying it but are in a collapsed state.
    <img src="${sample_collapsed}" />
  </p>
`,
  questions: [
    {
      question: "How many keys are deleted at the root level of the document?",
      type: "number",
      question_id: "1"
    },
    {
      question: "How many keys pairs are new at the root level of the document?",
      type: "number",
      question_id: "2"
    },
    {
      question: "How many elements are in the hobbies array after the patch?",
      type: "number",
      question_id: "3"
    }
  ],
  question_id: "example1"
}

export default training