import { Example } from "../SurveyType";
import visual from "../assets/ex2_visual.png";
import git from "../assets/ex2_git.png";
import jdif from "../assets/ex2_json_diff.png";
import original from "../assets/ex2_original.png";
import patch_img from "../assets/ex2_patch.png";

const training: Example = {
  title: "Question 2",
  github_description: `<img src="${git}" />`,
  json_diff_com_description: `<img src="${jdif}" />`,
  text_description: `<p>
  Original Document:<br>
  <img src=${original} /><br>
  Applied Patch:<br>
  <img src=${patch_img} />
  </p>
  `,
  visualizer_description: `<img src="${visual}" />`,
  questions: [
    {
      question:
        "How many employees are present in the new version of the JSON-Document?",
      type: "number",
      question_id: "1",
    },
    {
      question: "What is Alice's position after the patch?",
      type: "text",
      question_id: "3",
    }
  ],
  question_id: "example2",
};

export default training;
