import introduction from "./introduction"
import introduction_jsonpatch from "./introduction_jsonpatch"
import introduction_study from "./introduction_study"
import introduction_terminology from "./introduction_terminology"
import { Survey } from "../SurveyType"
import training from "./trainingQuestion"
import question1 from "./question1"
import question2 from "./question2"
import question3 from "./question3"
import question4 from "./question4"
import question5 from "./question5"
import question6 from "./question6"
import question7 from "./question7"
import question8 from "./question8"
import question9 from "./question9"
import question10 from "./question10"
import question11 from "./question11"
import question12 from "./question12"
import question13 from "./question13"
import question14 from "./question14"
import question15 from "./question15"
import question16 from "./question16";

const questions = [
  training, training, {...training, confirm: true, confirmText: "Ready to start the survey?"},
  question1, question2, question3, question4, question5,
  question6, question7, question8, question9, question10,
  question11, question12, question13, question14, question15
]

const survey_text = [
  introduction_study,
  introduction,
  introduction_terminology,
  ...questions.map(q => {
    return {
      title: q.title,
      description: `${q.text_description}`,
      question_id: q.question_id,
      questions: q.questions
    }
  }),
  question16
]

const survey_git = [
  introduction_study,
  introduction,
  introduction_terminology,
  ...questions.map(q => {
    return {
      title: q.title,
      description: `${q.github_description}`,
      question_id: q.question_id,
      questions: q.questions
    }
  }),
  question16
]

const survey_vis = [
  introduction_study,
  introduction,
  introduction_terminology,
  ...questions.map(q => {
    return {
      title: q.title,
      description: `${q.visualizer_description}`,
      question_id: q.question_id,
      questions: q.questions
    }
  }),
  question16
]
const survey_json_diff = [
  introduction_study,
  introduction,
  introduction_terminology,
  ...questions.map(q => {
    return {
      title: q.title,
      description: `${q.json_diff_com_description}`,
      question_id: q.question_id,
      questions: q.questions
    }
  }),
  question16
]




const survey: Survey = [
  introduction_study,
  introduction,
  introduction_terminology,
  ...questions.map((q, index) => {

    let description = ""
    let identifier = ""
    if(index % 3 == 0){
      description = q.github_description
      identifier = "github"
    }
    if(index % 3 == 1){
      description = q.json_diff_com_description
      identifier = "json_diff"
    }
    if(index % 3 == 2){
      description = q.visualizer_description
      identifier = "visualizer"
    }

    return {
      title: q.title,
      description,
      question_id: `${q.question_id}_${identifier}`,
      questions: q.questions,
      confirm: q.confirm || false,
      confirmText: q.confirmText
    }
  }),
  question16
]

export {
  survey as survey_all,
  survey_git,
  survey_json_diff,
  survey_text,
  survey_vis
}