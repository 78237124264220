import type { Operation } from "rfc6902";
import { parseJSON, parseJSONRecursive } from "./bracketParser";
import { AddOperation } from "rfc6902/diff";
import { log } from "console";
import { resolveObjectURL } from "buffer";
export class JSONLabel {
  label: string;
  type: number;
  children: JSONLabel[];
  identifier: string;
  num: number = -1;
  path: string = "";

  constructor(label: string, identifier: string, path: string = "") {
    this.identifier = identifier
    this.label = label;
    this.path = path
    this.children = []
    if(label == "{}") this.type = 0
    else if(label == "[]") this.type = 1
    else if(label.length >= 2 && label[label.length - 1] == ":") this.type = 2
    else this.type = 3
  }

  public setPath(path: string){
    this.path = path
  }

  public setNum(n: number){
    this.num = n
  }

  public getType() {
    return this.type
  }

  public getLabel() {
    return this.label
  }

  public equals(other: JSONLabel) {
    return other.type === this.type && other.label === this.label
  }

  public getName(){
    return this.label + ",path: " + this.path + ", type: " + this.type
  }
  public deepEquals(other: JSONLabel){
    return this.equals(other) && this.identifier == other.identifier && this.children == other.children
  }

  public addChild(child: JSONLabel) {
    this.children.push(child)
    if(this.type == 0){
      child.setPath(`${this.path}/${child.label.replace(":", "")}`)
    }else if(this.type == 1){
      child.setPath(`${this.path}/${this.children.length - 1}`)
    }else if(this.type == 2){
      if(child.type == 0) { child.setPath(`${this.path}`) }
      else if(child.type == 1) { child.setPath(`${this.path}`)}
      else if(child.type == 2) {}
      else { child.setPath(this.path)}
    }else{

    }
    return child
  }

  public getChildren() {
    return this.children
  }

  public getTreeSize(){
    let size = 1
    this.children.forEach(child => {
      size += child.getTreeSize()
    })

    return size
  }

  public print(level: number = 0, ongoing: string = ""){
    const indent = " ".repeat(level)
    let res = `${indent}${this.getName()}\n`
    this.children.forEach(c => {
      res += c.print(level+1, ongoing)
    })

    return res
  }

  public getAllLabels() {
    let labels: string[] = []
    this.getAllLabelsRecursion(labels)

    return labels;
  }

  public getAllLabelsRecursion(labels: string[]){
    labels.push(this.label)

    this.children.forEach(child => {
      child.getAllLabelsRecursion(labels)
    })
  }

  public isLeaf() {
    return this.children.length == 0
  }
  public makeJSON(): any {
    if (this.type == 1) {
      return this.children.map((a) => a.makeJSON());
    }
    if (this.type == 2) {
      let res = this.children[0].makeJSON();
      return res;
    }
    if (this.type == 3) return this.label;

    let res:any = {};
    this.children.forEach((a) => {
      let label = a.label;
      if (a.type == 2) label = label.substring(0, label.length - 1);

      res[label] = a.makeJSON(); 
    });
    return res;
  }

  public makeHierarchy(properties: HierarchyProps) {
    let {
      operations,
      isDeleted = false,
      isReplaced = false,
      isReplacement = false,
      replacedNode = null,
      isReplacementRoot = false,
    } = properties;
    let result: {
      name: string;
      __rd3t: any;
      attributes?: any;
      children: Array<any>;
    } = {
      name: this.label,
      children: [],
      __rd3t: {},
      attributes: {},
    };
    const removes = operations.filter(
      (op) => op.path == this.path && op.op == "remove"
    );
    const adds = operations.filter(
      (op) =>
        op.path.substring(0, op.path.lastIndexOf("/") + 1) == this.path &&
        op.op == "add"
    ) as AddOperation[];
    const replaces = operations.filter(
      (op) => op.path == this.path && op.op == "replace"
    ) as AddOperation[];
    const arrayAds = [];
    const objectAds = [];
    if (this.type == 1) {
      arrayAds.push(
        ...(operations.filter(
          (op) =>
            op.path.substring(0, op.path.lastIndexOf("/")) == this.path &&
            op.op == "add"
        ) as AddOperation[])
      );
    }
    if (this.type == 0) {
      objectAds.push(
        ...(operations.filter(
          (op) =>
            op.path.substring(0, op.path.lastIndexOf("/")) == this.path &&
            op.op == "add"
        ) as AddOperation[])
      );
    }

    if (removes.length > 0) isDeleted = true;

    if (isDeleted) {
      result.attributes.type = "deleted";
      result.attributes.fill = "red";
    }
    if (isReplacement) {
      if (isReplacementRoot) result.attributes.type = "replacement";
      result.attributes.fill = "lightblue";
    }
    if (isReplaced) {
      result.attributes.type = "replaced";
      result.attributes.fill = "brown";
    }
    if (this.identifier == "added") {
      result.attributes.type = "inserted";
      result.attributes.fill = "yellowgreen";
    }

    if (replacedNode) {
      result.attributes.show_original = true;
      result.attributes.replaced_node = replacedNode.makeHierarchy({
        operations: [],
        isDeleted: false,
      });
    }

    if (isReplacementRoot) {
      result.attributes.show_original = true;
    }

    if (adds.length > 0) {
      adds.forEach((add) => {
        const t = new JSONLabel(
          add.path.substring(add.path.lastIndexOf("/") + 1) + ":",
          "added"
        );
        parseJSONRecursive(add.value, "added", t);
        this.addChild(t);
      });
    }
    objectAds.forEach((op) => {
      const nl = new JSONLabel(
        op.path.substring(op.path.lastIndexOf("/") + 1) + ":",
        "added"
      );
      parseJSONRecursive(op.value, "added", nl);
      this.addChild(nl);
    });
    arrayAds.forEach((op) => {
      const nl = parseJSONRecursive(op.value, "added", this);
    });

    if (replaces.length > 0 && this.type != 3) {
      replaces.forEach((element) => {
        let oldVal = this.children.find((child) => child.path == element.path);
        const replacingNode = parseJSONRecursive(
          element.value,
          "replacement",
          this
        );
        result.attributes.replaced_node = oldVal?.makeHierarchy({
          operations: [],
          isReplaced: true,
          isDeleted: false,
        });
        result.children = [
          replacingNode.makeHierarchy({
            operations: [],
            isDeleted: false,
            isReplacement: true,
            isReplacementRoot: !isReplacement,
            replacedNode: oldVal,
          }),
        ];
        return result;
      });
    }
    if (replaces.length > 0 && this.type == 3) {
      const tempParent = new JSONLabel("", "fakeParent", this.path);
      const newNode = parseJSONRecursive(
        replaces[0].value,
        "original",
        tempParent
      );
      result = newNode.makeHierarchy({
        isReplacement: true,
        operations: [],
        isDeleted: false,
        isReplacementRoot: !isReplacement,
      });
      result.attributes.replaced_node = {
        name: this.label,
        attributes: { type: "replaced", fill: "brown" },
      };
      return result;
    }

    if (this.children.length > 0) {
      const newchildren = this.children
        .filter((child) => replaces.every((r) => r.path != child.path))
        .map((child) => {
          let childOps = operations.filter((op) =>
            op.path.startsWith(child.path)
          );
          return child.makeHierarchy({
            operations: childOps,
            isDeleted,
            isReplacement,
          });
        });
      result.children.push(...newchildren);
    }

    return result;
  }

  makeBasicHierarchy(){
    let result: {
      name: string;
      __rd3t: any;
      attributes?: any;
      children: Array<any>;
    } = {
      name: this.label.toString(),
      children: this.children.map(c => c.makeBasicHierarchy()),
      __rd3t: {
        id: this.num
      },
      attributes: {
        id: this.num
      },
    };


    return result
  }
  makeHierarchyFromTree(tree2?: JSONLabel, properties: any = {}) {
    let result: {
      name: string;
      __rd3t: any;
      attributes?: any;
      children: Array<any>;
    } = {
      name: this.label,
      children: [],
      __rd3t: {},
      attributes: {},
    };

    if (properties.fill) {
      result.attributes.fill = properties.fill;
    }

    if (!tree2) {
      //kein Gegenstück
      for (let child of this.children) {
        result.children.push(
          child.makeHierarchyFromTree(undefined, properties)
        );
      }
      return result;
    }
    console.log(this.path, tree2.path);

    // if(this.type == 2 && tree2.type == 2){
    //   if(this.label == tree2.label){
    //     result.attributes.
    //   }else{

    //   }
    // }

    if (this.type == 1 && tree2.type == 1) {
      //string edit distance machen wir hier oder nicht
    }

    //Typen checken
    if (this.type == 3 && tree2.type == 3) {
      if (this.label == tree2.label) {
        //gleicher node
        result.attributes.fill = "lightgreen";
      }
    }

    for (let child of this.children) {
      const match = tree2
        .getChildren()
        .find((a) => a.label === child.label && a.path == child.path);
      // console.log(this.label, child.path, match, match != undefined)
      if (match != undefined) {
        result.attributes.fill = "lightgreen";
        result.children.push(child.makeHierarchyFromTree(match, properties));
      } else {
        // result.attributes.fill = 'lightblue'
        result.children.push(
          child.makeHierarchyFromTree(undefined, { fill: "lightblue" })
        );
      }
    }

    for (let child of tree2.getChildren()) {
      const match = this.children.find((a) => a.label === child.label);
      if (match == undefined) {
        result.children.push(
          child.makeHierarchyFromTree(undefined, { fill: "orange" })
        ); //wurde eingefügt
      }
    }

    return result;
  }
}

type HierarchyProps = {
  operations: Operation[];
  isDeleted: boolean;
  isReplaced?: boolean;
  isReplacement?: boolean;
  replacedNode?: JSONLabel;
  isReplacementRoot?: boolean;
};
