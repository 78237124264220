 import { Operation } from "./jedi_baseline"
import { Matrix } from "./matrix"
import { JSONCostModel, LabelDictionary } from "../types/CostModel"
import { TreeIndex } from "../types/TreeIndexJSON"
import { JSONLabel } from "../types/label"
import { Operation as RFCOperation } from "rfc6902"
import { MoveOperation } from "rfc6902/diff"
import { InvalidOperationError, replace } from "rfc6902/patch"

const getCostById: (id_t1: number, id_t2: number) => number = (id_t1, id_t2) => {
  //@ts-ignore
  return dt.get(t1.inverted_list_label_id_to_postl.get(id_t1)[0] as number, t2.inverted_list_label_id_to_postl.get(id_t2)[0] as number
  )
}
const arrayMapping: (a: JSONLabel, b: JSONLabel, d: Matrix) => Array<{from: number, to: number}> = (arr1,arr2,d) => {
  let m = arr1.children.length;
  let n = arr2.children.length;
  let dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

  // Initialize the dp table
  for (let i = 0; i <= m; i++) {
    dp[i][0] = i; // Cost of deletions
  }
  for (let j = 0; j <= n; j++) {
    dp[0][j] = j; // Cost of insertions
  }

  // Fill the dp table
  for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        //@ts-ignore
        let cost = d.get(t1.inverted_list_label_id_to_postl.get(arr1.children[i - 1].num)[0] as number, t2.inverted_list_label_id_to_postl.get(arr2.children[j - 1].num)[0] as number)
          dp[i][j] = Math.min(
              dp[i - 1][j] + 1, // Deletion
              dp[i][j - 1] + 1, // Insertion
              dp[i - 1][j - 1] + cost // Substitution with precomputed cost
          );
      }
  }
  
    // Backtrack to find the operations and mappings
    let operations = [];
    let mappings = [];
    let i = m, j = n;
    let itcount = 0
    while (i > 0 && j > 0 && itcount < 50) {
        //@ts-ignore
        const c = d.get(t1.inverted_list_label_id_to_postl.get(arr1.children[i - 1].num)[0] as number, t2.inverted_list_label_id_to_postl.get(arr2.children[j - 1].num)[0] as number)
        if (dp[i][j] === dp[i - 1][j - 1] + c) {
            operations.push(`Substitute ${arr1.children[i - 1].path} with ${arr2.children[j - 1].path}, ${arr1.children[i-1].deepEquals(arr2.children[j-1])}`);
            mappings.push({ from: i - 1, to: j - 1 });
            i--; j--;
        } else if (dp[i][j] === dp[i - 1][j] + 1) {
            operations.push(`Delete ${arr1.children[i - 1].path} from index ${i-1}`);
            i--;
        } else if (dp[i][j] === dp[i][j - 1] + 1) {
            operations.push(`Insert ${arr2.children[j - 1].path} at index ${j-1}`);
            j--;
        }
        itcount++;
    }
    // Handle remaining operations
    while (i > 0) {
        operations.push(`Delete ${arr1.children[i - 1].path} from index ${i-1}`);
        i--;
    }
    while (j > 0) {
        operations.push(`Insert ${arr2.children[j - 1].path} at index ${j-1}`);
        j--;  
    }

    return mappings.reverse()
}

const patch_v1: (operations: Operation[], t1: TreeIndex, t2: TreeIndex, tree1: JSONLabel, tree2: JSONLabel, cm: JSONCostModel, ld: LabelDictionary, dt: Matrix) => Array<any> = (operations, t1,t2,tree1,tree2,cm, ld, dt) => {
  let list: Array<Operation> = [operations[operations.length-1]] //0-9

  let iterations = 0
  let Edits: any = []
  let Adds: any = []
  let Removes: any = []
  
  
  
  while(list.length != 0 && iterations <= 350){
    const original = list[0] //operation
    const original_node = cm.label_dic.get(original.name[0])
    const new_node = cm.label_dic.get(original.name[1])
    let tempList: Array<Operation> = []
    let doUnmapping = true
    let totalChildCost = 0
  
    original.mapping.forEach(operation => {
      let {node_source, node_target} = operation
      const i: number[] = t1.inverted_list_label_id_to_postl.get(node_source) as number[]
      const j: number[] = t2.inverted_list_label_id_to_postl.get(node_target) as number[]
      if(i && j){
        const cost_for_mapping = dt.get(
          (i[0]+1) as number,
          (j[0]+1) as number
        )
        
        if(cost_for_mapping != 0){
          const temp = operations.find(a => a.name[0] == node_source && a.name[1] == node_target)
          if(temp) tempList.push(temp)
        }
        totalChildCost += cost_for_mapping
      }
    })
  
    if(totalChildCost == original.cost){
      //hier gleich updaten
    }else{
      if(iterations != 0){
        if(original_node.type == 1 && new_node.type == 1){
          doUnmapping = false
          let mapping = arrayMapping(original_node, new_node, dt)
          mapping.forEach(({from, to}) => {
            const from_node = original_node.children[from]
            const to_node = new_node.children[to]
            const cost = getCostById(from_node.num, to_node.num)
  
            if(cost != 0){
                const temp = operations.find(a => a.name[0] == from_node.num && a.name[1] == to_node.num)
                if(temp && temp.cost != 0) tempList.push(temp)
            }
          })
  
  
  
          original_node.children.filter((a,i) => mapping.every(m => m.from != i)).forEach(toDel => {
            Removes.push({op: "remove", path: toDel.path})
          })
          new_node.children.filter((b, i) => mapping.every(m => m.to != i)).forEach(toIns => {
            Adds.push({op: "add", path: toIns.path, value: toIns.makeJSON(),origin: original})
          })
  
  
  
        }else{
          if(original_node.type != new_node.type) {
          }
          if(original.possibles.includes("rename") ){
            if(original_node.type == 3){
              Edits.push({op: "replace", path: new_node.path, value: new_node.makeJSON(), same_type: original_node.type == new_node.type, same_label: original_node.label == new_node.label, poss: original.possibles})
            }else if(original_node.type == 2){
              const new_path = original_node.path.substring(0, original_node.path.lastIndexOf("/")) + "/" + (new_node.label+ "").replace(":", "")
              Edits.push({op: "move", from: original_node.path, path: new_path, same_type: original_node.type == new_node.type})
            }else{
              console.log("uu", original, original_node.type, new_node.type)
            }
          }
        }
      }
    }
  
    if(doUnmapping){
      const ungemapped_children_new = new_node.children.filter(child => original.mapping.every(a => a.node_target != child.num))
      const ungemapped_children_old = original_node.children.filter(child => original.mapping.every(a => a.node_source != child.num))
    
      ungemapped_children_new.forEach(a => {
        Adds.push({op: "add", path: a.path, value: a.makeJSON(), origin: original.mapping, pso: original.possibles})
      })
      ungemapped_children_old.forEach(a => {
        Removes.push({op: "remove", path: a.path})
      })
    }
  
    list = [...list.splice(1),...tempList]
    iterations++
  }
  console.log({iterations})
  console.log("Edits:", Edits.length)
  Edits.sort((a: any, b: any) => {
    return b.path.split("/").length - a.path.split("/").length
  })
  // console.log(tree1.print(0,"", 2))
  // console.log(tree2.print(0,"", 2))
  
  
  console.log(cm.label_dic.print())
  const patch = [...Removes, ...Edits,...Adds]
  return patch//:(
}


const removeUnmapped: (node: JSONLabel, op: Operation) => RFCOperation[] = (node, op) => {
  return node.children.filter(child => op.mapping.every(o => o.node_source != child.num)).map(unmapped => {
    return { op: "remove", path: unmapped.path }
  })
}
const insertUnmapped: (node: JSONLabel, op: Operation) => RFCOperation[] = (node, op) => {
  // console.log("INSERT", node.children.map(a => a.getName()))
  return  node.children.filter(child => op.mapping.every(o => o.node_target != child.num)).map(unmapped => {
    return { op: "add", path: unmapped.path, value: unmapped.makeJSON() }
  })
}

const patch_v2: (ops: Operation[], ld: LabelDictionary) => Array<any> = (ops, ld) =>{

  const getCostById: (id_t1: number, id_t2: number) => number = (id_t1, id_t2) => {
    const res = ops.find(a => a.name[0] == id_t1 && a.name[1] == id_t2)
    if(res) return res.cost
    return -1
  }
  const ArrayToObject: (op: Operation, indent?: string) => RFCOperation[] = (operation, indent = "") => {
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])
    const result: RFCOperation[] = []
    const count = node_old.children.length
    result.push({ op: "add", path: `${node_old.path}/${count}`, value: {}})

    operation.mapping.forEach(({node_source, node_target}) => {
      const map_source = ld.get(node_source)
      const map_target = ld.get(node_target)
      const next_op = getOperationByIds(node_source, node_target)


      const child_patch = []
      if(next_op){
        child_patch.push(...recursivePatch(next_op))
      }
      child_patch.forEach(child => {
        const t = child.path.substring(node_old.path.length+1)
        if(!parseInt(t.split("/")[0]) && child.path.startsWith(node_new.path) && child.op != "replace"){
          child.path = node_old.path + `/${count}/` + child.path.substring(node_new.path.length+1)
        }

        result.push(child)
      })
    })

    result.push({op: "move", from: `${node_old.path}/${count}`, path: node_old.path})
    return result
  }

  const ObjectToArray: (op: Operation, indent?: string) => RFCOperation[]  = (operation, indent = "") => {
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])
    let result: RFCOperation[] = []
    
    const first: RFCOperation = { op: "add", path: `${node_old.path}/temp_copy`, value: []}

    operation.mapping.forEach(({node_source, node_target}) => {
      let next_op = getOperationByIds(node_source, node_target)
      let next_source = ld.get(node_source)
      let next_target = ld.get(node_target)
      if(next_op) result.push(
        ...(recursivePatch(next_op, indent).map(op =>{
          op.path = `${node_old.path}/temp_copy/${next_target.path.substring(node_old.path.length-1)}`
          return op
        }))
      )
    })
    
    node_new.children.forEach(child => {
      if(!operation.mapping.find(a => a.node_target == child.num)){
        result.push({
          op: "add",
          path: `${node_old.path}/temp_copy/${child.path.substring(node_old.path.length-1)}`,
          value: child.makeJSON()
        })
      }
    })

    result.sort((a, b) => {
      const getLastNumber = (path: string) => {
          const match = path.match(/\/(\d+)$/);
          return match ? parseInt(match[1], 10) : -1; // return -1 if there's no number at the end
      };
      const getOpVal = (op: string) => {
        if(op == "add") return 0
        if(op == "move") return 1
        if(op == "replace") return 2
        return 3
      }
  
      const aLastNumber = getLastNumber(a.path);
      const bLastNumber = getLastNumber(b.path);
      
      if(aLastNumber == bLastNumber){
        return getOpVal(a.op) - getOpVal(b.op)
      }
      return aLastNumber - bLastNumber;
    });

    result = [first, ...result, ]

    result.push({op: "move", from: `${node_old.path}/temp_copy`, path: node_old.path})
    return result
  }

  const getOperationByIds: (id1: number, id2: number) => Operation | undefined = (id1,id2) => {
    return ops.find(o => o.name[0] == id1 && o.name[1] == id2) 
  }

  const insert:(operation: Operation, first?: boolean) => RFCOperation[] = (operation, first = false) => {
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])
    let result : RFCOperation[] = []
  

    let leastChildCost = 0
    let leastChildOp: Operation | null = null;

    if(operation.cost == 0){
      return [
        {
          op: "move",
          from: node_old.path,
          path: node_new.path
        }
      ]
    }

    if(node_new.type == 3 && node_old.type == 3 && operation.cost != 0){
      result.push({
        op: "replace",
        value: node_new.makeJSON(),
        path: node_old.path
      })
      result.push({
        op: "move",
        from: node_old.path,
        path: node_new.path
      })
    }
    const removis: Array<Operation> = []
    node_old.children.forEach((child) => {
      const childOp = getOperationByIds(child.num, node_new.num)
      if(childOp){
        if(leastChildOp){
          if(childOp.cost < leastChildCost){
            leastChildCost = childOp.cost
            removis.push(leastChildOp)
            leastChildOp = childOp
          }else{
            removis.push(childOp)
          }
        }else{
          leastChildCost = childOp.cost
          leastChildOp = childOp
        }
      }
    })

    removis.forEach((op: Operation) => {
      result.push({op: "remove", path: ld.get(op.name[0]).path})
    })
    if(leastChildOp){
      if(leastChildCost < operation.cost){
        result.push(...insert(leastChildOp))
      }else {
        result.push(...recursivePatch(operation))
        result.push({
          op: "move",
          path: node_new.path,
          from: node_old.path
        })
      }
    }


    if(node_new.type == 0 && node_old.type == 1){//arraytoobject
      result = [
        {
          op: "add",
          path: node_old.path + "/0",
          value: {}
        },
        ...result.filter(a => a.op != "move" && a.path != node_old.path).map(child => {
          if(child.path.startsWith(node_new.path + "/")){
            child.path = node_old.path + "/0" + child.path.substring(node_new.path.length)
          }
          if(child.op == "replace"){
            //@ts-ignore
            child.op = "add"
          } 
          if(child.path == node_old.path){
            child.path = node_old.path + "/0" + child.path.substring(node_old.path.length)
          }
          return child
        }),
        {
          op: "move",
          from: node_old.path + "/0",
          path: node_new.path
        }
      ]
    }

    return result;
  }

  const insertOperation: (op: Operation, indent?: string) => RFCOperation[] = (operation, indent = "") => {
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])
    const result : RFCOperation[] = []

    // temp.push({op: "move", from: node_old.path, path: node_new.path })
    if(node_new.type == 3) {
      result.push({ op: "replace", path: node_old.path, value: node_new.makeJSON()})
      return result
    }

    const ccost = getCostById(node_old.children[0].num, node_new.num) //cost of child to target
    if(ccost != 0){
      const cmp = getOperationByIds(node_old.children[0].num, node_new.num)
      if(cmp) {
        const temp = recursivePatch(cmp, indent)

        if(node_new.type == node_old.type && node_old.path != node_new.path && node_new.path.split("/").length == node_old.path.split("/").length){
          temp.push({op: "remove", path: node_old.path})
        }

        if(node_new.type == node_old.type && node_old.path != node_new.path && node_new.path.split("/").length != node_old.path.split("/").length){
          temp.push({op: "move", from: node_old.path, path: node_new.path})
        }
        result.push(...temp)
      }
    }else{
      result.push({
        op: "move",
        path: node_new.path,
        from: node_old.children[0].path
      })
      result.push({
        op: "remove",
        path: node_old.path,
      })
    }
    
    return result
  }

  const delete2: (op: Operation, first?: boolean) => RFCOperation[] = (operation, first = false) => {
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])
    let result : RFCOperation[] = []

   
    let leastChildCost = 0
    let leastChildOp: Operation | null = null;
    let totalOp = 0
    node_new.children.forEach((child) => {
      const childOp = getOperationByIds(node_old.num, child.num)
      if(childOp){
        totalOp += childOp.cost
        if(leastChildOp){
          if(childOp.cost < leastChildCost){
            leastChildCost = childOp.cost
            leastChildOp = childOp
          }
        }else{
          leastChildCost = childOp.cost
          leastChildOp = childOp
        }
      }
    })
    // console.log({leastChildOp, nt: node_new.type, ot: node_old.type, c: operation.cost})
    if(leastChildOp){
      if(leastChildCost < operation.cost){
        if(node_new.type == 0){
          if(node_old.type == 3){
            result.push({
              op: "replace",
              path: first ? node_old.path : node_new.path,
              value: {}
            })
          }else{
            result.push({
              op: "add",
              path: first ? node_old.path : node_new.path,
              value: {}
            })
          }
        }
        if(node_new.type == 1){
          result.push({
            op: "add",
            path:  first ? node_old.path : node_new.path,
            value: []
          })
        }
        node_new.children.filter(a => a.num != leastChildOp?.name[1]).forEach(childToInsert => {
          result.push({op: "add", path: childToInsert.path, value: childToInsert.makeJSON()})
        })

        const temp = delete2(leastChildOp)
        // const temp = recursivePatch(leastChildOp)
        // if(first) console.log({temp})
        result.push(...temp)
      } else {
        const temp = recursivePatch(operation)
        result.push(...temp)
        if(!temp.find(a => a.path == node_new.path && a.op == "move")){
          result.push({
            op: "copy",
            path: node_new.path,
            from: node_old.path
          })
        }
      }
    }else{
      if(operation.cost != 0){
        result.push({
          op: "add",
          value: node_new.makeJSON(),
          path: node_new.path
        })
      }
    }

    if(node_old.path == node_new.path){
      result = result.map((child) => {
        if(child.op == "replace") return child
        child.path = node_new.path + "_temp" +  child.path.substring(node_new.path.length)
        return child
      })
      result.push({
        op: "move",
        from: node_old.path + "_temp",
        path: node_old.path
      })
    }

    return result;
  }

  const deleteOperation: (op: Operation, indent?: string) => RFCOperation[] = (operation, indent = "") => {
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])
    const result : RFCOperation[] = []

    if(node_old.type == 3){
      result.push({op: "move", path: node_new.path, from: node_new.makeJSON()})
      return result
    }



    let childOp = getOperationByIds(node_old.children[0].num, node_new.children[0].num)
    const ccost = getCostById(node_old.num, node_new.children[0].num) //cost of child to target

    // if(ccost == 0){
    //   result.push({op: "add", path: node_new.path, value: node_new.makeJSON()})
    // }else{
    //   if(childOp){
    //     result.push(...recursivePatch(childOp))
    //   }
    // }
    if(ccost != 0){
      const cmp = getOperationByIds(node_old.num, node_new.children[0].num)
      if(cmp) {
        const temp = recursivePatch(cmp, indent).map((child) => {
          // console.log("child", child)
          return child
        })
        result.push(...temp)
      }
    }else{
      result.push({
        op: "replace",
        path: node_new.path,
        value: node_new.makeJSON()
      })
      // result.push({
      //   op: "move",
      //   path: node_new.path,
      //   from: node_old.path
      // })
    }
    
    return result
  }
  const renameOperation: (op: Operation, indent?: string) => RFCOperation[] = (operation, indent = "") => {
    const node_old = ld.get(operation.name[0])
    const node_new = ld.get(operation.name[1])
    
    let result: RFCOperation[] = []

    // console.log("rename", node_old, node_new)
    if(operation.cost == 0) return result

    if(node_old.type == 3 && node_new.type == 3){
      return [{ op: "replace", path: node_old.path, value: node_new.makeJSON()}]
    }
  
    if(node_old.type == 1 && node_new.type == 1){
      if(operation.array_mapping == undefined ) return []


      let c = operation.array_mapping.cols-1;
      let r = operation.array_mapping.rows-1;

      const tempMap: Array<[number, number]> = []
      while(c != 0 || r != 0){
        if(c == 0){
          result.push( { op: "remove", path: node_old.path + "/0" } )
          r--
          continue;
        }
        if(r == 0){
          result = [ ...result, { op: "add", path: `${node_old.path}/0`, value: node_new.children[c-1].makeJSON() }]
          c--
          continue;
        }
        
        const rename = operation.array_mapping.get(r-1, c-1)
        const insert = operation.array_mapping.get(r, c-1)
        const del = operation.array_mapping.get(r-1, c)

        const min = Math.min(rename, insert, del)
        switch(min){
          case rename:
            tempMap.push([node_old.children[r-1].num, node_new.children[c-1].num])
            const maped = getOperationByIds(node_old.children[r-1].num, node_new.children[c-1].num)
            if(maped) {
              const oldc = node_old.children[r-1]
              const newc = node_new.children[c-1]
              const temp = recursivePatch(maped, indent).map( child => {
                if(child.path.startsWith(newc.path + "/") && (child.op == "move" || child.op == "add")){
                  child.path = oldc.path + child.path.substring(newc.path.length)
                }
                return child
              })
              result = [
                ...temp,
                ...result
              ]
            }
            r--
            c--
            break;
          case del:
            result.push( { op: "remove", path: node_old.children[r-1].path } )
            r--
            break;
          case insert:
            result = [...result, { op: "add", path: `${node_old.path}/${c-1}`, value: node_new.children[c-1].makeJSON() }]
            c--
            break;
        }
      }
    }

    if(node_old.type == 2 && node_new.type == 2){
      const childrenOp = getOperationByIds(node_old.children[0].num, node_new.children[0].num)
      if(!childrenOp) return []
  
      const childrenCost = childrenOp.cost
      let move: RFCOperation | undefined = undefined
      if(childrenCost < operation.cost){//rename neccessary
        move = { op: "move", from: node_old.path, path: node_new.path }
      }
      if(childrenCost != 0){
        const children = recursivePatch(childrenOp,indent)

        result.push(...children.map(child => {
          if(child.op == "move" && child.from != node_old.path){
            if(child.path.startsWith(node_new.path + "/")){
              child.path = node_old.path + child.path.substring(node_new.path.length)
            }
          }
          if(child.path.startsWith(node_new.path + "/")){
            child.path = node_old.path + child.path.substring(node_new.path.length)
          }
          
          return child
        }))  
      }

      
      if(!result.find(a => (a.op == "move" && a.from == node_old.path) || a.op == "move" && a.path == node_new.path)){
        if(move) result.push(move)
      }
    }

    if(node_old.type == 0 && node_new.type == 0){
      const pathMapping = operation.mapping.map(m => [ld.get(m.node_source).path, ld.get(m.node_target).path])
      // if(node_old.num == 35) console.log({result})
      operation.mapping.forEach(mapping => {
        const o = getOperationByIds(mapping.node_source, mapping.node_target)
        if(o) result.push(...recursivePatch(o, indent+"  "))
      })
      
      const insert = (insertUnmapped(node_new, operation))
      // console.log({insert}, node_old.type, node_new.type)
      result.push(...insert)
      result.push(...removeUnmapped(node_old, operation).filter(r => pathMapping.every(p => p[1] != r.path)))
    }

    if(node_new.type == 0 && node_old.type == 1){
      result.push(...ArrayToObject(operation))
    }

    if(node_new.type == 1  && node_old.type == 0){
      result.push(...ObjectToArray(operation))
    }

    if((node_new.type == 3 && node_old.type == 1) || (node_old.type == 1 && node_new.type==3)){
      result.push({
        op: "replace",
        path: node_new.path,
        value: node_new.makeJSON()
      })
    }

    if(node_new.type == 3 && node_old.type == 0){
      result.push({
        op: "replace",
        path: node_new.path,
        value: node_new.makeJSON()
      })
    }
   
    return result
  }

  const recursivePatch: (operation: Operation, indent?: string, clean?: boolean) => RFCOperation[] = (operation, indent: string = "", clean = false) => {
    const ren = operation.possibles.includes("rename")
    const del = operation.possibles.includes("delete")
    const ins = operation.possibles.includes("insert")
    const old_node = ld.get(operation.name[0])
    const new_node = ld.get(operation.name[1])


    // console.log(indent, old_node.path, new_node.path,operation.possibles, old_node.type, new_node.type, operation.cost)
    indent += "  "
    const result: RFCOperation[] = []
    if(ren) result.push(...renameOperation(operation, indent))
    // if(del && !ren) result.push(...deleteOperation(operation, indent))
    if(del && !ren) result.push(...delete2(operation, true))
    // if(ins && !del && !ren) result.push(...insertOperation(operation, indent))
    if(ins && !del && !ren) result.push(...insert(operation, true))
    
    // if(!clean) return result
    
    let cleans: RFCOperation[] = []
    const old_node_labels = old_node.children.map(a => a.path)
    const new_node_lables = new_node.children.map(a => a.path)

    const only_old = old_node_labels.filter(a => new_node_lables.every(o => o != a))

    for(let i = 0; i < result.length; i++){
      const currentOp = result[i]
      if(currentOp.op == "move"){
        for(let j = 0; j < i; j++){
          let nestedOp = result[j]
          if(nestedOp.op == "move" && nestedOp.path == currentOp.from && nestedOp.path.split("/").length == nestedOp.from.split("/").length ){
            result[j].path += "_temp"

            cleans.push({op: "move", from: `${currentOp.from}_temp`, path: currentOp.from})
          }
        }
      }
    }
    
    if(!clean) return result 
    if(new_node.type == old_node.type && old_node.type != 1)
    only_old.forEach(a => {
      if(!result.find(r => {
        // @ts-ignore
        if(r.from){ 
          // @ts-ignore
          return r.from == a
        }
        return false
      })){
        if(!result.find(res => res.op =="remove" && res.path == a)){
          cleans.push({
            op: "remove",
            path: a
          })
        }
      }
    })



    return [...result, ...cleans]
  }



  return recursivePatch(ops[ops.length-1]);
}
const patch_v3 = () => {

}

export {
  patch_v1,
  patch_v2,
  patch_v3
}

