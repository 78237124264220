//@ts-ignore
import React, {useState} from 'react';
import tree_map from "./assets/tree_map.png"
export default function LandingPage() {
  return <>
    <section>
      <h2>The JSON Edit-Distance</h2>
      <div className="section-content">
        <div className='full'>
          <p>The JavaScript Object Notation (JSON) is a popular data format used
in document stores to natively support semi-structured data. In this
paper, we address the problem of JSON similarity lookup queries:
given a query document and a distance threshold 𝜏, retrieve all
JSON documents that are within 𝜏 from the query document. Due
to its recursive definition, JSON data are naturally represented as
trees. Different from other hierarchical formats such as XML, JSON
supports both ordered and unordered sibling collections within a
single document. This feature poses a new challenge to the tree
model and distance computation. We propose JSON tree, a lossless
tree representation of JSON documents, and define the JSON Edit
Distance (JEDI), the first edit-based distance measure for JSON
documents. We develop an algorithm, called QuickJEDI, for computing JEDI by leveraging a new technique to prune expensive
sibling matchings. It outperforms a baseline algorithm by an order of magnitude in runtime. To boost the performance of JSON
similarity queries, we introduce an index called JSIM and a highly
effective upper bound based on tree sorting. Our algorithm for the
upper bound runs in 𝑂(𝑛𝜏) time and 𝑂(𝑛 + 𝜏 log𝑛) space, which
substantially improves the previous best bound of 𝑂(𝑛
2
) time and
𝑂(𝑛 log𝑛) space (where 𝑛 is the tree size). Our experimental evaluation shows that our solution scales to databases with millions of
documents and JSON trees with tens of thousands of nodes.
          </p>
        </div>
      </div>
    </section>
    <section>
      <h2>The Mapping</h2>
      <div className="section-content">
        <div className='left'>
          <p>The JavaScript Object Notation (JSON) is a popular data format used
in document stores to natively support semi-structured data. In this
paper, we address the problem of JSON similarity lookup queries:
given a query document and a distance threshold 𝜏, retrieve all
JSON documents that are within 𝜏 from the query document. Due
to its recursive definition, JSON data are naturally represented as
trees. Different from other hierarchical formats such as XML, JSON
supports both ordered and unordered sibling collections within a
single document. This feature poses a new challenge to the tree
model and distance computation. We propose JSON tree, a lossless
tree representation of JSON documents, and define the JSON Edit
Distance (JEDI), the first edit-based distance measure for JSON
documents. We develop an algorithm, called QuickJEDI, for computing JEDI by leveraging a new technique to prune expensive
sibling matchings. It outperforms a baseline algorithm by an order of magnitude in runtime. To boost the performance of JSON
similarity queries, we introduce an index called JSIM and a highly
effective upper bound based on tree sorting. Our algorithm for the
upper bound runs in 𝑂(𝑛𝜏) time and 𝑂(𝑛 + 𝜏 log𝑛) space, which
substantially improves the previous best bound of 𝑂(𝑛
2
) time and
𝑂(𝑛 log𝑛) space (where 𝑛 is the tree size). Our experimental evaluation shows that our solution scales to databases with millions of
documents and JSON trees with tens of thousands of nodes.
          </p>
        </div>
        <div className='right'>
          <img src={tree_map} alt="Map of trees" />
        </div>
      </div>
    </section>
  </>
}