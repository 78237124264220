type Constants = {
  tree_size: number
  tree_degree: number
  tree_height: number
}

type ListKR = {
  list_kr: number[]
}

type PostLToType = {
  postl_to_type: number[]
}

type PostLToLabelId = {
  postl_to_label_id: number[]
}

type PostLToSize = {
  postl_to_size: number[]
}

type PostLToChildren = {
  postl_to_children: number[][]
}

type PostLToParent = {
  postl_to_parent: number[]
}

type PostLToDepth = {
  postl_to_depth: number[]
}

type PostLToLCh = {
  postl_to_lch: number[]
}

type PostLToSubtreeMaxDepth = {
  postl_to_subtree_max_depth: number[]
}

type PostLToKRAncestor = {
  postl_to_kr_ancestor: number[]
}

type PostLToFavChild = {
  postl_to_fav_child: number[]
  postl_to_left_fav_child: number[]
}

type PostLToHeight = {
  postl_to_height: number[]
}

type PostLToOrderedChildSize = {
  postl_to_ordered_child_size: number[][]
}

type PostLToFavorableChildOrder = {
  postl_to_favorder: number[]
}

type PostLToLeftSibling = {
  postl_to_left_sibling: number[]
}

type InvertedListLabelIdToPostL = {
  inverted_list_label_id_to_postl: Map<number, number[]>;
}


export type TreeIndexJSON = 
  Constants & 
  ListKR &
  PostLToType &
  PostLToLabelId &
  PostLToSize &
  PostLToChildren &
  PostLToParent &
  PostLToDepth &
  PostLToLCh &
  PostLToSubtreeMaxDepth &
  PostLToKRAncestor &
  PostLToFavChild &
  PostLToHeight &
  PostLToOrderedChildSize &
  PostLToFavorableChildOrder &
  PostLToLeftSibling &
  InvertedListLabelIdToPostL

export class TreeIndex implements TreeIndexJSON {
  tree_size: number = -1
  tree_degree: number = -1
  tree_height: number = -1
  list_kr: number[] = []
  postl_to_type: number[] = []
  postl_to_label_id: number[] = []
  postl_to_size: number[] = []
  postl_to_children: number[][] = []
  postl_to_parent: number[] = []
  postl_to_depth: number[] = []
  postl_to_lch: number[] = []
  postl_to_subtree_max_depth: number[] = []
  postl_to_kr_ancestor: number[] = []
  postl_to_fav_child: number[] = []
  postl_to_left_fav_child: number[] = []
  postl_to_height: number[] = []
  postl_to_ordered_child_size: number[][] = []
  postl_to_favorder: number[] = []
  postl_to_left_sibling: number[] = []
  inverted_list_label_id_to_postl: Map<number, number[]> = new Map<number, number[]>()
}