import { Example } from "../SurveyType";
import visual from "../assets/ex7_visual.png";
import git from "../assets/ex7_git.png";
import jdif from "../assets/ex7_json_diff.png";
import original from "../assets/ex7_original.png"
import patch_img from "../assets/ex7_patch.png"

const training: Example = {
  title: "Question 7",
  github_description: `<img src="${git}" />`,
  json_diff_com_description: `<img src="${jdif}" />`,
  text_description: `<p>
  Original Document:<br>
  <img src=${original} /><br>
  Applied Patch:<br>
  <img src=${patch_img} />
  </p>
  `,
  visualizer_description: `<img src="${visual}" />`,
  questions: [
    {
      question: "Under what key is the main foodSource stored in the original JSON-Document?",
      type: "text",
      question_id: "1",
    },
    {
      question: "How many elements are in the wildlife array in the original JSON-Document?",
      type: "number",
      question_id: "2",
    },
  ],
  question_id: "example7",
};

export default training;
