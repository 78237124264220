//@ts-ignore
import React, { useEffect, useRef, useState } from "react";
import Tree, { CustomNodeElementProps } from "react-d3-tree";
import { parseJSON, parseJSONRecursive } from "./utils/bracketParser";
import { createPatch } from "rfc6902";
import { compare } from "fast-json-patch";
import * as jedi from "./implementation/jedi";
import {
  jedi_and_patch,
  makeHierarchyFromEditOperations,
} from "./implementation/jedi";

const sample1 = {
  ecosystem: "Amazon Rainforest",
  layers: [
    {
      name: ["Canopy"],
      species: [
        { commonName: "Harpy Eagle", scientificName: "Harpia harpyja" },
        "Hase",
        { commonName: "Howler Monkey", scientificName: "Alouatta caraya" },
      ],
    },
  ],
};
const sample2 = {
  ecosystem: "Amazon Rainforest",
  layers: [
    {
      name: "Canopy",
      species: [
        "simulant",
        {
          commonName: "Harpy Eagle",
          scientificName: "Harpia harpyja",
          type: "bird",
        },
        {
          commonName: "Howler Monkey",
          scientificName: "Alouatta caraya",
          type: "monkey",
        },
        "asfd",
      ],
    },
  ],
};
const sample3 = {
  name: {
    "first": "Max",
    "last": "Mustermann"
  },
  age: 24,
}
const sample4 = {
  name: [
    "Max",
    "Mustermann"
  ],
  years: 24,
  foo: "bar"
}




const patch = [
  { op: "add", path: "/layers/0/species/0/type", value: "bird" },
  { op: "add", path: "/layers/0/species/1/type", value: "monkey" },
  { op: "add", path: "/layers/0/species/3/type", value: "bird" },
  { op: "add", path: "/layers/0/species/4/type", value: "frog" },
  { op: "add", path: "/layers/1/species/0/type", value: "cat" },
  { op: "add", path: "/layers/1/species/1/type", value: "snake" },
  { op: "add", path: "/layers/1/species/4/type", value: "fish" },
  { op: "remove", path: "/layers/0/species/2" },
  { op: "remove", path: "/layers/1/species/2" },
  { op: "remove", path: "/layers/1/species/3" },
];

const renderRectSvgNode = ({
  nodeDatum,
  toggleNode,
}: CustomNodeElementProps) => {
  if (nodeDatum.attributes) {
    if (nodeDatum.attributes.rerender) {
      nodeDatum.attributes.rerender = false;
      toggleNode();
    }
  }

  try {
    nodeDatum.name.substring(0, 15);
  } catch {
    console.log("erro", nodeDatum);
  }

  if(nodeDatum.attributes.skipPaths) return <></>
  return (
    <g id={nodeDatum.attributes.id} style={{ zIndex: 10 }}>
      <ellipse
        rx="65"
        ry="20"
        fill={nodeDatum.attributes.fill || "lightgrey"}
        onClick={toggleNode}
      ></ellipse>
      {/* <circle r="15" fill={nodeDatum.attributes.fill || "lightgrey"} onClick={() => toggleNode()}/> */}
      <text
        textAnchor="middle"
        dy="5"
        fill="black"
        strokeWidth="1"
        onClick={toggleNode}
      >
        {nodeDatum.name.substring(0, 15)}
      </text>
      {nodeDatum.attributes.type == "replacement" && (
        <text
          textAnchor="middle"
          dy="30"
          strokeWidth="1"
          fill="black"
          onClick={() => {
            if (nodeDatum.attributes.show_original) {
              nodeDatum.attributes.original_name = nodeDatum.name;
              nodeDatum.name = nodeDatum.attributes.replaced_node.name;
              nodeDatum.attributes.show_original = false;
              nodeDatum.attributes.original_children = nodeDatum.children;
              nodeDatum.children =
                nodeDatum.attributes.replaced_node.children || [];
              nodeDatum.attributes.rerender = true;
              nodeDatum.attributes.fill = "brown";
            } else {
              nodeDatum.name = nodeDatum.attributes.original_name;
              nodeDatum.attributes.show_original = true;
              nodeDatum.attributes.rerender = true;
              nodeDatum.attributes.fill = "lightblue";
              nodeDatum.children = nodeDatum.attributes.original_children;
            }
            toggleNode();
          }}
        >
          Show {nodeDatum.attributes.show_original ? "old" : "new"} version
        </text>
      )}
    </g>
  );
};

function getTranslation(element) {
  const transform = element.getAttribute('transform');
  if (transform) {
      const match = transform.match(/translate\(([^,]+),\s*([^\)]+)\)/);
      if (match) {
          return {
              x: parseFloat(match[1]),
              y: parseFloat(match[2])
          };
      }
  }
  return { x: 0, y: 0 }; // Default to (0, 0) if no transform is found
}

export default function AppD3JsonTree() {
  const [chart, setChart] = useState(null);
  const [edits, setEdits] = useState([])
  const [s1, setS1] = useState(JSON.stringify(sample3, null, " "));
  const [s2, setS2] = useState(JSON.stringify(sample4, null, " "));
  const treeRef = useRef();
  useEffect(() => {
    const result = jedi_and_patch(sample3, sample4);
    // const result = jedi_and_patch(sample4, sample3);

    setEdits(result.edits)
    setChart(makeHierarchyFromEditOperations(result.operations, result.ld));
    //10-65
  }, []);

  return (
    <>
      <div className="input">
        <textarea
          id="t1"
          rows={15}
          value={s1}
          onChange={(e) => setS1(e.target.value)}
          placeholder="Original Document"
        ></textarea>
        <textarea
          id="t2"
          rows={15}
          value={s2}
          onChange={(e) => setS2(e.target.value)}
          placeholder="Target Document"
        ></textarea>
      </div>
      <div className="controls">
        <button
          onClick={() => {
            const doc1 = JSON.parse(s1);
            const doc2 = JSON.parse(s2);
            const result = jedi_and_patch(doc1, doc2);
        
            setEdits(result.edits)
            setChart(makeHierarchyFromEditOperations(result.operations, result.ld));
          }}
        >
          Show Edit Mapping
        </button>
      </div>
      <div id="treeWrapper" style={{ width: "100vw", height: "100vh" }}>
        {chart && (
          <Tree
            ref={treeRef}
            onUpdate={(nodeDatum) => {
              const svg = document.querySelector(
                "." + treeRef.current.gInstanceRef
              );

              const removes = document.querySelectorAll(".temp")
              removes.forEach(r => {
                r.remove()
              })

              edits.forEach((edit) => {
                const ren = edit.possibles.includes("rename")
                const ins = edit.possibles.includes("insert")
                const del = edit.possibles.includes("delete")
                if(ren){
                  const g1 = document.getElementById(edit.name[0]); 
                  const g2 = document.getElementById(edit.name[1]); 
  
                  if(!g1 || !g2) return
                  const pos1 = getTranslation(g1.parentElement);
                  const pos2 = getTranslation(g2.parentElement);
                   // Calculate the control point for a curved line
                  const midX = (pos1.x + pos2.x) / 2;
                  const midY = (pos1.y + pos2.y) / 2;
  
                  const controlX = midX;
                  const controlY = midY - 100; // Adjust this value to control the curvature
  
                  const pathData = `M${pos1.x},${pos1.y} Q${controlX},${controlY} ${pos2.x},${pos2.y}`;
  
                  const line = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                  line.setAttribute('d', pathData);
                  line.setAttribute('class', 'rd3t-link temp');
                  line.setAttribute('style' ,'z-index: 0; stroke: orange')
                  svg.insertBefore(line, svg.firstChild);
                }

                if(ins && !ren){
                  const g1 = document.getElementById(edit.name[0])
                  if(!g1) return;
                  g1.firstChild.setAttribute('fill', 'red')
                }
                if(del && !ins && !ren){
                  const g2 = document.getElementById(edit.name[1])
                  if(!g2) return;
                  g2.firstChild.setAttribute('fill', 'lightgreen')
                }
              })

              
            }}
            translate={{ x: (window.visualViewport?.width || 0) / 2, y: 20 }}
            orientation={"vertical"}
            renderCustomNodeElement={renderRectSvgNode}
            pathFunc={(linkDatum, orientation) => {
              // console.log({linkDatum, orientation})
              const { source, target } = linkDatum;
              if (source.data.attributes.skipPaths) return;

              return orientation === "horizontal"
                ? `M${source.y},${source.x}L${target.y},${target.x}`
                : `M${source.x},${source.y}L${target.x},${target.y}`;
            }}
            data={chart}
          />
        )}
      </div>
    </>
  );
}
