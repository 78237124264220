import type { QuestionMeta } from "../SurveyType";
import orig from "../assets/training_original.png"
const introduction_terminology: QuestionMeta = {
  title: "Terminology",
  description: `
<p>
There are a few terms you will need in order to complete this survey:
</p>
<p>
<h3>root level:</h3>
The root level refers to the immediate children of the root node <span class="code">{}</span>
</p>
<p>
<h3>key-value pairs:</h3>
A key value pair is how values in JSON are stored.<br>
<span class="code">{"name": "Stefan"}</span><br>
In this example the key is <span class="code">name</span> and the value is <span class="code">Stefan</span>
</p>
<p>
<h3>changing operation:</h3>
A changing operation refers to applying a single change to a JSON-Document. This could be: <br>
* adding an element to an array<br>
* changing the key of a key-value pair<br>
* changing the value of a key-value pair<br>
* deleting a key and all its underlying values from the JSON-Document<br>
</p>
  `,
  question_id: "introduction",
  questions: []
}

export default introduction_terminology