import { Example } from "../SurveyType";
import visual from "../assets/ex13_visual.png";
import git from "../assets/ex13_git.png";
import jdif from "../assets/ex13_json_diff.png";
import original from "../assets/ex13_original.png"
import patch_img from "../assets/ex13_patch.png"

const training: Example = {
  title: "Question 13",
  github_description: `<img src="${git}" />`,
  json_diff_com_description: `<img src="${jdif}" />`,
  text_description: `<p>
  Original Document:<br>
  <img src=${original} /><br>
  Applied Patch:<br>
  <img src=${patch_img} />
  </p>
  `,
  visualizer_description: `<img src="${visual}" />`,
  questions: [
    {
      question: "How many elements are in the classes array for middle-school in the new version of the JSON-document?",
      type: "number",
      question_id: "1",
    },
    {
      question: "What value is stored in the new grade under the key: 'level'?",
      type: "text",
      question_id: "2",
    },
  ],
  question_id: "example13",
};

export default training;
